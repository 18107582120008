
@font-face {
    font-family: 'Bauhaus_Medium_BT';
    src: url("../../assets/fonts/Bauhaus_Medium_BT.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}


@font-face {
    font-family: 'HelveticaNeueW01-55Roma';
    src: url(https://cdn.shopify.com/s/files/1/1988/2877/t/3/assets/b7693a83-b861-4aa6-85e0-9ecf676bc4d6d41d.eot?516640827146118396?#iefix);
    src: url(https://cdn.shopify.com/s/files/1/1988/2877/t/3/assets/b7693a83-b861-4aa6-85e0-9ecf676bc4d6d41d.eot?516640827146118396?#iefix) format("eot"),url(https://cdn.shopify.com/s/files/1/1988/2877/t/3/assets/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff?516640827146118396) format("woff"),url(https://cdn.shopify.com/s/files/1/1988/2877/t/3/assets/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf?516640827146118396) format("truetype"),url(https://cdn.shopify.com/s/files/1/1988/2877/t/3/assets/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg?516640827146118396#da09f1f1-062a-45af-86e1-2bbdb3dd94f9) format("svg")
}


@font-face {
    font-family: 'HelveticaNeueW01-UltLt';
    src: url(" //cdn.shopify.com/s/files/1/1677/0009/t/3/assets/41774233-b9da-44be-b252-6a7b612fb1c7d41d.eot?%23iefix&7218510895950755566 ");
    src: url(" //cdn.shopify.com/s/files/1/1677/0009/t/3/assets/41774233-b9da-44be-b252-6a7b612fb1c7.eot?%23iefix&7218510895950755566 ") format("embedded-opentype"),
    url(" //cdn.shopify.com/s/files/1/1677/0009/t/3/assets/4bff1fbb-b4bf-4d95-9c47-efcb14384e36.woff?7218510895950755566 ") format("woff"),
    url(" //cdn.shopify.com/s/files/1/1677/0009/t/3/assets/7f1f2a7d-3837-4c93-b373-f03c5da3f9a1.ttf?7218510895950755566 ") format("truetype"),
    url(" //cdn.shopify.com/s/files/1/1677/0009/t/3/assets/d9f2752a-8d82-4cf1-b82f-109c1105be7f.svg%23d9f2752a-8d82-4cf1-b82f-109c1105be7f?7218510895950755566 ") format("svg");
    font-weight: normal;
    font-style: normal
}



@font-face {
    font-family: "HelveticaNeueW01-45Ligh";
    src: url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/ae1656aa-5f8f-4905-aed0-93e667bd6e4ad41d.eot?516640827146118396?#iefix");
    src: url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/ae1656aa-5f8f-4905-aed0-93e667bd6e4ad41d.eot?516640827146118396?#iefix") format("eot"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/530dee22-e3c1-4e9f-bf62-c31d510d9656.woff?516640827146118396") format("woff"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/688ab72b-4deb-4e15-a088-89166978d469.ttf?516640827146118396") format("truetype"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/7816f72f-f47e-4715-8cd7-960e3723846a.svg?516640827146118396#7816f72f-f47e-4715-8cd7-960e3723846a") format("svg")
}


@font-face {
    font-family: "HelveticaNeueW01-Thin";
    src: url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/56be84de-9d60-4089-8df0-0ea6ec786b84d41d.eot?516640827146118396?#iefix");
    src: url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/56be84de-9d60-4089-8df0-0ea6ec786b84d41d.eot?516640827146118396?#iefix") format("eot"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/50d35bbc-dfd4-48f1-af16-cf058f69421d.woff?516640827146118396") format("woff"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/278bef59-6be1-4800-b5ac-1f769ab47430.ttf?516640827146118396") format("truetype"),
    url("//cdn.shopify.com/s/files/1/1988/2877/t/3/assets/2e309b1b-08b8-477f-bc9e-7067cf0af0b3.svg?516640827146118396#2e309b1b-08b8-477f-bc9e-7067cf0af0b3") format("svg")
}




.HelveticaNW01-45Lall {
    font-family: 'HelveticaNeueW01-45Ligh', Helvetica, Arial, Helvetica Neue, Helvetica, Arial, sans-serif;
}



/* 
=====================
   1. Base styles
   
===================== 
*/

html {
    text-shadow: 0px 0px 0px rgba(0,0,0,0.0);  
}

body {
    color: #333;
  font-family: 'HelveticaNeueW01-55Roma', Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 14px;
    overflow-x: hidden;
}

a,p,span,h1,h2,h3,h4,h5,h6,blockquote,address, dl, ol, ul {
  padding:0px;
  margin:0px;
}

ol{
    padding-left:30px;
}


h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6{
    color: inherit;
    font-family: inherit;
    font-weight: 500;
}

a{
    outline: none !important;
    color:#333;
    text-decoration: none;
}

a:focus,
a:hover,
a:active,
input:focus,
input:hover,
input:active,
button:focus,
button:hover,
button:active{
    outline: none !important;
    text-decoration: none; 
       opacity:0.9;  
}


select:focus {
    outline:none;
}

ul{
    list-style:none;
}


.btn {
    border-radius: 4px;
}


img{
    max-width:100% !important;
}


.Bauhaus_Medium_BT {
    font-family: 'Bauhaus_Medium_BT';
    letter-spacing: -4px;
}

.Bauhaus_Medium_BT:hover {
    color: #333;
}



  /* datatable  */

  .DatatableOne{
    font-size:25px;
}

.DatatableOne .rdt_TableCol{
    font-size:12px;
    text-transform: capitalize;
    font-weight:bold;
    padding:0px;
}

.Datatable_edited_clinic_list .rdt_TableCol{
    font-size:13px;
    text-transform: capitalize;
    /* font-weight:bold; */
}

.DatatableOne .rdt_TableCell{
    /* font-size:16px; */
    text-transform: capitalize;
}

table th,
table td{
    font-weight:100;
}

.cxcOiE{
    min-height:44px !important;  
}

.Datatable_edited .rdt_TableCell{
    display: block;
    border-right: 1px solid rgba(0,0,0,.12);
}

.Datatable_edited .rdt_TableCell:nth-child(1){
    border-left: 6px solid #1A83DE;
}

.Datatable_edited .rdt_TableCell{
    width:100%;
    float:left;  
    display:block;
    /* line-height:44px; */
    cursor:pointer;
}

.Datatable_edited .rdt_TableCell a{
    width:100%;
    float:left;  
    display:block;
    line-height:16px;
    cursor:pointer;
}

.Datatable_edited .rdt_TableCell a:hover{
    color:#333;

}

.Datatable_edited_2 .rdt_TableCell{
    display: block;
    border-right: 1px solid rgba(0,0,0,.12);
}



.Datatable_edited_2 .rdt_TableCell:nth-child(1){
    border-left: 6px solid #1A83DE;
}

.Datable_dlist .rdt_TableCell:nth-child(1){
    border-left: 6px solid #FF5700;
}



.Datatable_edited_2 .rdt_TableCell a{
    width:100%;
    float:left;  
    display:block;
    line-height:44px;
    cursor:pointer;
}

.Datatable_edited_2 .rdt_TableCell a:hover{
    color:#333;

}


.Datable_dlist .rdt_TableRow:last-child,
.Datatable_edited .rdt_TableRow:last-child
{
      border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.Datable_dlist .rdt_TableRow,
.Datatable_edited .rdt_TableRow
{
      min-height:auto !important;
}

.Datable_dlist .rdt_TableCell a{
      line-height:16px;
}

.Datatable_edited .rdt_TableCell,
.Datable_dlist .rdt_TableCell
 {
      padding-left:10px;
      padding-right:10px;
      padding-top:16px;
      padding-bottom:15px;
}



/******************* table *************************/


/******************* pagination_custom *************************/

.pagination_custom{
    width:100%;
    float:left;

}

.pagination_custom_first{
    width:30%;
    float:left;      
}

.pagination_total_count{
    display:none;
}

.pagination_custom_select_one{
    padding: 7px 5px 5px;
    font-weight: 100;
    border: 1px solid #1677ff;   
    border-radius: 6px;
}


.pagination_custom_second{
    width:70%;
    float:left;      
}

.pagination_custom_second_left{
    float:right;
    margin-right:15px;
    position:relative;
    top:5px;      
}

.pagination_custom_second_middle{
    float:right;
    margin:0px 15px;
}

.pagination_custom_second_right{
    float:right;
    margin-left:15px;
    position:relative;
    top:-1px;      
}

.pagination_items .pagination_inactive_item:hover{
    transition: all 0.2s;
    background-color: rgba(0, 0, 0, 0.06);    
}

.pagination_items .pagination_items_inner{
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-inline-end: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    user-select: none;      
}

.pagination_items .pagination_items_inner_dot{
    display: inline-block;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    transition: all 0.2s;
    text-align: center;
    vertical-align: middle;
    margin-right:8px;
    
    font-weight: bold;
    position: relative;
    top: -3px;      
}

.pagination_items .pagination_active_item{
    font-weight: 600;
    background-color: #ffffff;
    border: 1px solid #1677ff;   
}

.pagination_items .pagination_active_item:hover{
    border-color: #4096ff;  
}

.pagination_arrow{
    position: relative;
    top: 1px;
    font-size: 12px;
}

.pagination_arrow_active {
    cursor:pointer;
}

.pagination_arrow_inactive {
    opacity:.5;
    cursor: not-allowed; 
    pointer-events: none;
}

.pagination_left_arrow{
    margin-right: 25px;
}

.pagination_right_arrow{
    margin-left:10px;
}


.pagination_custom_input_one{
    padding: 6px 5px 3px;
    font-weight: 600;
    border: 1px solid #1677ff;   
    border-radius: 6px;
}


@media (max-width: 1000px) {
    .pagination_custom_first{
          width:100%;    
          margin-bottom:20px;
          text-align: center;
    }
    
    .pagination_custom_second{
          width:100%;  
          text-align: center;
    }

    .pagination_custom_second_left,
    .pagination_custom_second_middle,
    .pagination_custom_second_right{
          float:none;
          display:inline-block;
    }

    .pagination_custom_second_left {
          top: 2px;
      }

    .pagination_custom_second_right {
          top: 2px;
      }

}

@media (max-width: 800px) {
    .pagination_custom_second_left,
    .pagination_custom_second_right {
          display:none;
    }


          
    .pagination_total_count{
          margin-right:15px;
          display:inline-block;
    }

}

/******************* pagination_custom *************************/




.updateButton1db{
    border:1px solid #c9cbcf;
    border-radius: 10px;
    cursor:pointer;
    padding: 5px 15px;
}




