
/************ Login **************/
.ge_input {
      border: 1px solid #c9cbcf;
      border-radius: 4px;
      color: #202124;
      font-size: 14px;
      height: 48px;
      line-height: 48px;
      padding: 0 15px;
}


.ge_input_login{
      background: #068bff;
      border: 1px solid #068bff;
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      padding: 0 30px;      
}
/************ Login **************/


/**************************/
.dashboard_container{
      display: flex;
      flex-wrap: wrap;
      gap: 24px; 
      padding: 16px;     
}

.dashboard_item {
      flex: 1 1 calc(100% - 24px); 
      max-width: calc(100% - 24px);

      background-color: #ffffff; 
      padding: 24px; 
      border-radius: 8px; 
      /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  */
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
      transition: transform 0.3s;
}

@media screen and (max-width: 639px) {
      .dashboard_item {
            flex: 1 1 100%; 
            max-width: 100%;
      }
}  




.custom-box:hover {
      transform: scale(1.05);
}

.dashboard_heading {
      font-size: 1.25rem;
      margin-bottom: 1rem; 
      text-transform: capitalize;
      text-align: center;
}

.dashboard_text {
      color: #4B5563; 
}

/* Responsive breakpoints */
@media (min-width: 640px) { /* sm: */
      .dashboard_item {
            flex: 1 1 calc(50% - 24px); /* 2 columns */
            max-width: calc(50% - 24px);
      }
}

@media (min-width: 768px) { /* md: */
      .dashboard_item {
            flex: 1 1 calc(33.333% - 24px); /* 3 columns */
            max-width: calc(33.333% - 24px);
      }
}

@media (min-width: 1024px) { /* lg: */
      .dashboard_item {
            flex: 1 1 calc(25% - 24px); /* 4 columns */
            max-width: calc(25% - 24px);
      }
}

@media (min-width: 1280px) { /* xl: */
      .dashboard_item {
            flex: 1 1 calc(20% - 24px); /* 5 columns */
            max-width: calc(20% - 24px);
      }
}

/************ listing page **************/
.listing_title_1{
      font-size:25px;
      line-height: 25px;
}

.listing_search .ant-input-affix-wrapper{
      border-radius: 4px !important;
}

/* .listing_search .ant-input-group-addon{
      display:none;
} */

@media screen and (max-width: 320px) {

      .listing_title_1{
            font-size:20px;
            line-height: 20px;
      }

}  
  


.add_plus{
      float:right;
      margin-left:10px;
      font-size:25px;
      line-height: 25px;
      color:#0B57D0 !important
}

.add_plus_symbol{
      font-size:40px;
      line-height: 30px;
      font-weight:bold;   
      color:#bd120d;
}

/************ listing page end**************/




/**************************/


.segment_heading p{
      color: #4ec1fe;
      margin-bottom:10px;
}


.dental_links li{
      float:left;
}

.dental_links li a{
      padding: 14px 10px;
      font-size: 14px;
}

.dental_links li:first-child a{
      padding-left:0px;
}
.dental_links li:last-child a{
      padding-right:0px;
}

@media screen and (max-width: 1175px) {
      .dental_links li a{
            padding: 14px 15px;
      }
      .dental_links li:first-child a{
            padding-left:15px;
      }

} 





/**********  **********/
.success_status{
      margin-top: 10px;
      margin-bottom: 20px;      
}
.success_status p{
      color: green;
      font-size: 14px;    
}

.error_status {
      margin-top: 10px;
      margin-bottom: 20px;
}
  
.error_status p {
      color: red;
      font-size: 14px;
}

.error_messages {
      margin-top: 10px;
      margin-bottom: 20px;
}
  
.error_messages p {
      color: red;
      font-size: 14px;
}
/**********  **********/



/**********  **********/
.input_text input[type=text],
.input_text input[type=email],
.input_number_full
{
      margin-bottom:10px;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 34px;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* Create a custom checkbox */

/* The container */
.form-check-inline1 {
      display: block;
      position: relative;
      padding-left: 24px;
      margin-bottom: 6px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight:normal;
    }
    
    /* Hide the browser's default checkbox */
    .form-check-inline1 input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }


.checkmark {
      position: absolute;
      top: 0;
      left: 0;
        width: 18px;
        height: 18px;
        border: 2px solid #ddd;
        border-radius: 2px;
        background: #fff;
    }
    
    /* On mouse-over, add a grey background color */
    .form-check-inline1:hover input ~ .checkmark {
      background: #2196F3;
      border: 2px solid #2196F3;
    }
    
    /* When the checkbox is checked, add a blue background */
    .form-check-inline1 input:checked ~ .checkmark {
      background: #2196F3;
      border: 2px solid #2196F3;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    .form-check-inline1 input:checked ~ .checkmark:after {
      display: block;
    }
    
    /* Style the checkmark/indicator */
    .form-check-inline1 .checkmark:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }


.custom_select {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }


.custom_select_mini {
      display: block;
      height: 25px;
      padding: 5px;
      font-size: 12px;
      line-height: 25px;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }



/********** radio **********/
/* The container */
.radiocontainer {
      display: inline-block;
      position: relative;
      padding-left: 24px;
      margin-bottom: 6px;
      margin-left:4px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    /* Hide the browser's default radio button */
    .radiocontainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    /* Create a custom radio button */
    .radiocheckmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 50%;
    
      border: 2px solid #ddd;
      background: #fff;  
    
    }
    
    /* On mouse-over, add a grey background color */
    .radiocontainer:hover input ~ .radiocheckmark {
      background: #2196F3;
    }
    
    /* When the radio button is checked, add a blue background */
    .radiocontainer input:checked ~ .radiocheckmark {
      background: #fff;
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radiocheckmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the indicator (dot/circle) when checked */
    .radiocontainer input:checked ~ .radiocheckmark:after {
      display: block;
    }
    
    /* Style the indicator (dot/circle) */
    .radiocontainer .radiocheckmark:after {
           top: 1px;
          left: 1px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #2196F3;
    }
    


/**************************************/

.datepicker_one .ant-picker{
      margin-bottom: 10px;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 34px;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/**************************************/    


/**************************************/   

.checkoboxMultiple .ant-checkbox-wrapper{
      min-width:45%;
      float:left;
      padding:5px 0px 5px 0px;
      margin:0;
}

/* .checkoboxMultiple {
      width:100%;
      display: flex;
      flex-wrap: wrap; 
      gap: 10px; 
}
    
.checkoboxMultiple .ant-checkbox-wrapper {
      flex: 0 0 45%; 
      box-sizing: border-box; 
} */
/**************************************/ 

/**************************************/   

.btn_submit{
      color:#fff;
      background-color: #2196f3;
      display: inline-block;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0px solid transparent;
      padding: 0 14px;
      font-size: 12px;
      line-height: 1.78571;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      position: relative;
      min-width: 30px;
      height: 35px;
      line-height: 35px;
      outline: none;
      text-decoration: none;
      text-transform: uppercase;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      letter-spacing: 1px;
      -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      -o-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      will-change: box-shadow;
}

.btn_submit_small{
      color:#fff;
      background-color: #2196f3;
      display: inline-block;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0px solid transparent;
      padding: 0 10px;
      font-size: 12px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      position: relative;
      min-width: 25px;
      height: 22px;
      line-height: 22px;
      outline: none;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
}
/**************************************/    

/**************************************/ 
.image_upload .ant-upload-list-item-container {
      display:block !important;   
}  

.image_upload .ant-upload.ant-upload-select {
      /* float:left;
      width: 50%;
      height: auto;
      text-align: center;
      vertical-align: top; */
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px dashed #fff !important;
      border-radius: 8px;
      cursor: pointer;
      transition: border-color 0.3s;     
}   
/**************************************/    


/**************************************/  
.file-upload-container {
      text-align: center;
      margin: 20px;
    }
    
    .custom-button {
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .custom-button:hover {
      background-color: #0056b3;
    }  
/**************************************/  


/**************************************/  
.switch_main {
      width:100%;
      float:left;
      height:35px;
}  

.switch_main_left{
      width:45%;
      float:left;
      margin-top:5px;
}

.switch_span {
      float:left;
      width:65%;
}  
.switch_main_right{
      width:55%;
      float:left;
}
/**************************************/  


/**************************************/  
/* Container for phone input */

  
.react-international-phone-country-selector{
      width:25%;
      float:left;
      text-align: center;
}
.react-international-phone-country-selector-button{
      width:100%;
}

.react-international-phone-flag-emoji{
      width:15px !important;
}

.react-international-phone-input{
      width:75%;
      float:left;
}



/**************************************/    
/**************************************/  

/******************ant multi select********************/
.ant-select-selection-overflow,
.dentals_multi_select .ant-select-selection-overflow
 {
      cursor:pointer;
}  
.dentals_multi_select .ant-select-selector {
      padding-inline-end:0px !important;
}  
.dentals_multi_select  .ant-select-selection-overflow-item{
      width:90%;
      padding-bottom:5px;
}

.dentals_multi_select  .ant-select-selection-overflow-item .ant-select-selection-item{

      padding-top:5px;
      padding-bottom:5px;
}

.dentals_multi_select  .ant-select-selection-overflow-item-suffix{
      padding:10px;
}

.dentals_multi_select  .ant-select-selection-item{
      height:auto !important;
      width:100%;
}
.dentals_multi_select  .ant-select-selection-item-content{
      overflow:visible !important;
      white-space: break-spaces !important;
      width:100%;
}
/******************ant multi select********************/  
  
/******************* table *******************/  

.table_container {
      width: 100%;
      overflow-x: auto; 
}

.table_container table {
      min-width: 600px;
      /* table-layout: fixed !important;
      width: 100% !important; */
}

@media (max-width: 600px) {
      /* .responsive-column[data-index="country"] {
            display: none; 
      } */
}

.table_container .ant-table-tbody tr td:first-child{
      border-left: 6px solid #1a83de;
}

.table_container tr th{
      padding:5px 10px !important;
      margin:0px;
      font-size:13px;
      line-height:15px;
} 


.table_container  tr td{
      padding:5px 10px !important;
      margin:0px;
      font-size:13px;
      line-height:15px;
      height:44px;
      color: rgba(0, 0, 0, 0.87);    

      vertical-align: middle;
      border-top: 1px solid #dee2e6;

} 


.table_container .react-international-phone-country-selector{
      width: auto !important;
      padding: 0 !important;
      margin: 0 !important;
}
.table_container .react-international-phone-country-selector-button--disabled,
.table_container .react-international-phone-input-container .react-international-phone-input--disabled{
      background-color: #fff !important;
      border: 0px solid #fff  !important;
      padding: 0 !important;
      margin: 0 !important;
      color:rgba(0, 0, 0, 0.87);
      opacity: 1 !important;
}
.table_container .react-international-phone-flag-emoji{
      opacity: 1;
}

.table_container .react-international-phone-country-selector-button__dropdown-arrow--disabled{
      display: none !important;
      padding: 0 !important;
      margin: 0 !important;
}





.dataTable_1db_container .react-international-phone-country-selector{
      width: auto !important;
      padding: 0 !important;
      margin: 0 !important;
}
.dataTable_1db_container .react-international-phone-country-selector-button--disabled,
.dataTable_1db_container .react-international-phone-input-container .react-international-phone-input--disabled{
      background-color: #fff !important;
      border: 0px solid #fff  !important;
      padding: 0 !important;
      margin: 0 !important;
      color:rgba(0, 0, 0, 0.87);
      opacity: 1 !important;
      height:auto;
}

.dataTable_1db_container .react-international-phone-flag-emoji{
      opacity: 1;
}

.datatable_one_container .react-international-phone-flag-emoji{
      height:16px;
}

.dataTable_1db_container .react-international-phone-country-selector-button__dropdown-arrow--disabled{
      display: none !important;
      padding: 0 !important;
      margin: 0 !important;
}




/******************* table *******************/    

/******************* pagination *************************/

/* Ensure container is a flex container and centered */
.pagination-dropdown-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
}
    
.pagination_dd{
      cursor:pointer;
      margin-top: 5px;
      border: 1px solid #d9d9d9;
      padding: 4px 8px;
      border-radius: 4px;      
}


@media (max-width: 500px) {
      .pagination-dropdown-container {
            flex-direction: column; 
            align-items: center;
      }

      .pagination-dropdown-container > * {
            margin-bottom: 10px; 
      }

      .pagination-dropdown-container > :last-child {
            margin-bottom: 0;
      }
}

/******************* pagination *************************/

.text_ellipsis{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block; 
      max-width: 100%;
}



 