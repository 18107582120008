
/* -------------------------------------------------------------------
Responsive Media Querries
---------------------------------------_media-query.scss.liquid---------------------------- */

/* custom container width */
 
  
  @media screen and (max-width: 1919px) {}  
  @media screen and (max-width: 1900px) {}  
  @media screen and (max-width: 1650px) {}  


  @media screen and (max-width: 1175px) {
    .dn-1175{display:none;}
    .db-1175{display:block;}
    .fw-1175{width:100%;}
    .mt0-1175{margin-top:0px;}
  }  

  @media screen and (max-width: 767px) {
    .w80p_767{width:80%;}
    .fw_767{width:100%;}
    .mr0_767{margin-right:0px;}
    .mt30_767{margin-top:30px;}
    .mb30_767{margin-bottom:30px;}
    .ml0_767{margin-left:0;}
    .ml10p_767{margin-left:10%;}
    .tc_767{text-align:center;}
    .fs25_767{font-size: 25px;} 
    .lh25_767{line-height: 25px;}

  }    
  
  
  @media screen and (max-width: 550px) {

    .w20p_550{width:20%;}
    .w80p_550{width:80%;}

  }  

  @media screen and (max-width: 400px) {

    .w10p_400{width:10%;}
    .w90p_400{width:90%;}

  }  

  @media screen and (max-width: 320px) {
    .w50p_320{width:50%;}

    .mr5_320{margin-right: 5px;}
  }  


  /* @media screen and (max-width: 550px) {

    .w20p_550{width:20%;}
    .w80p_550{width:80%;}

  }   */


  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  













