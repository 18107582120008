/* custom container width */


@media screen and (min-width: 1300px) {
    .container {
        max-width: (1300px - 30px);
      	width:92%;
    } 
}
    
  

@media screen and (min-width: 1650px) {
    .container {
        max-width: (1650px - 30px);
      	width:92%;
    }
}


@media screen and (min-width: 1900px) {
    .container {
        max-width: (1900px - 30px);
      	width:92%;
    }

}


.vertical-text {
	transform: rotate(90deg);
	transform-origin: left top 0;
}

.overflow-scroll{
	overflow:scroll;
}
.overflow-x-hidden{
	overflow-x:hidden;
}
.overflow-y-hidden{
	overflow-y:hidden;
}


.overflow-x-auto{
	overflow-x:auto;
}
.overflow-y-auto{
	overflow-y:auto;
}


.overflow-x-scroll{
	overflow-x:scroll;
}
.overflow-y-scroll{
	overflow-y:scroll;
}


.transotion-all{transition: all 0.5s ease;}

.transition-all-1{transition: all 0.1s ease;}
.transition-all-2{transition: all 0.2s ease;}
.transition-all-3{transition: all 0.3s ease;}
.transition-all-4{transition: all 0.4s ease;}
.transition-all-6{transition: all 0.6s ease;}


.transition-width{
 transition: width 0.5s ease;
}

.rotate90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.rotate-90 {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
/* custom global classes */
.text-shadow {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2)
}

.text-strong {
    font-family: 'Roboto-RC-Regular', sans-serif!important;
    font-weight: initial !important
}

.full-opacity{opacity:1 !important;}

.md{
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* radius */


.rad0{
-webkit-border-radius: 0px;
-moz-border-radius: 0px;
border-radius: 0px;
}


.rad4{
-webkit-border-radius: 4px;
-moz-border-radius: 4px;
border-radius: 4px;
}

.rad8{
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
}

.rad12{
-webkit-border-radius: 12px;
-moz-border-radius: 12px;
border-radius: 12px;
}

.bnone{
    border:0px solid #fff;
}

.bnone-im{
    border:0px solid #fff !important;
}

.b-red{border: 1px solid #eb5d68;}
.b-blue{border: 1px solid #0B57D0;}
.bb-blue{border-bottom: 1px solid #0B57D0;}
.b-b{border: 1px solid #000;}
.b-w{border:1px solid #fff;}
.b-grey{border:1px solid #ccc;}


/* color  */


.cw{
    color:#fff;
}



.cw-h:hover{
    color:#fff;
}

.cred{
    color:#eb5d68;
}

.cred-h:hover{
    color:#eb5d68;
}

.cred_2{
    color:#ff0000;
}

.cred_3{
    color:#FF0000;
}

.cs_b{color:#00C875 !important;}
.cs_r{color:#ff0000 !important;}
.cs_orange{color:#ff9900 !important;}
.cs_green{color:#274e13 !important;}
.cs_green_2{color:#274e13 !important;}

.cs_b a{color:#00C875 !important;}
.cs_r a{color:#ff0000 !important;}
.cs_orange a{color:#ff9900 !important;}
.cs_green a{color:#274e13 !important;}
.cs_green_2 a{color:#274e13 !important;}






/* background  */
.bgw{
    background:#fff;
}
.bgblue{
    background:#0B57D0;
}

.bggreen{
    background:#274e13;
}

.cp{
    cursor:pointer;
}

.cn{
    cursor:text;
}

.bg_w_n{
    background:#F8F8F8;
}

/* height */

.h100vh{height:100vh;}
.h40vh{height:40vh;}
.h50vh{height:50vh;}
.h60vh{height:60vh;}
.h70vh{height:70vh;}
.h75vh{height:75vh;}
.h80vh{height:80vh;}
.h90vh{height:90vh;}
.h95vh{height:95vh;}
.h34vh{height:34vh;}
.h25vh{height:25vh;}

.auto-height,
.h_auto{height:auto;}

.h100vh_m{min-height:100vh;}

.h40vh_m{min-height:40vh;}
.h50vh_m{min-height:50vh;}
.h60vh_m{min-height:60vh;}
.h70vh_m{min-height:70vh;}
.h75vh_m{min-height:75vh;}
.h80vh_m{min-height:80vh;}
.h85vh_m{min-height:85vh;}
.h90vh_m{min-height:90vh;}
.h95vh_m{min-height:95vh;}
.h34vh_m{min-height:34vh;}
.h25vh_m{min-height:25vh;}
.auto-height-min{height:auto;}
 

.window-height-max{height:100vh;}

.window-forty-height-max{max-height:40vh;}
.window-half-height-max{max-height:50vh;}
.window-sixty-height-max{max-height:60vh;}
.window-seventy-height-max{max-height:70vh;}
.window-seventyFive-height-max,
.window-upper-quarter-height-max{max-height:75vh;}
.window-eighty-height-max{max-height:80vh;}
.window-ninety-height-max{max-height:90vh;}
.window-ninetyfive-height-max{max-height:95vh;}
.window-onr-third-height-max{max-height:34vh;}
.window-quarter-height-max{max-height:25vh;}

.full-height{height:100%;}
.single-height{height:1px;}
.no-height{height:0px;}

.h0{height:0px;}
.h1{height:1px;}
.h2{height:2px;}
.h20{height:20px;}
.h25{height:25px;}
.h30{height:30px;}
.h31{height:31px;}
.h32{height:32px;}
.h33{height:33px;}
.h34{height:34px;}
.h35{height:35px;}
.h36{height:36px;}
.h37{height:37px;}
.h38{height:38px;}
.h39{height:39px;}
.h40{height:40px;}
.h45{height:45px;}
.h50{height:50px;}
.h54{height:54px;}
.h60{height:60px;}
.h65{height:65px;}
.h66{height:66px;}
.h67{height:67px;}
.h68{height:68px;}
.h69{height:69px;}
.h70{height:70px;}
.h80{height:80px;}
.h90{height:90px;}
.h100{height:100px;}
.h110{height:110px;}
.h120{height:120px;}
.h130{height:130px;}
.h140{height:140px;}
.h150{height:150px;}
.h200{height:200px;}
.h250{height:250px;}
.h300{height:300px;}
.h350{height:350px;}
.h400{height:400px;}
.h450{height:450px;}
.h500{height:500px;}
.h550{height:550px;}
.h600{height:600px;}
.h650{height:650px;}


.h25-min{min-height:25px;}
.h30-min{min-height:30px;}
.h31-min{min-height:31px;}
.h32-min{min-height:32px;}
.h33-min{min-height:33px;}
.h34-min{min-height:34px;}
.h35-min{min-height:35px;}
.h36-min{min-height:36px;}
.h37-min{min-height:37px;}
.h38-min{min-height:38px;}
.h39-min{min-height:39px;}
.h40-min{min-height:40px;}
.h50-min{min-height:50px;}
.h100-min{min-height:100px;}
.h150-min{min-height:150px;}
.h200-min{min-height:200px;}
.h250-min{min-height:250px;}
.h300-min{min-height:300px;}
.h400-min{min-height:400px;}
.h500-min{min-height:500px;}
.h600-min{min-height:600px;}

.full-height-max{max-height:100%;}
.single-height-max{max-height:1px;}
.no-height-max{max-height:0px;}
.h25-max{max-height:25px;}
.h30-max{max-height:30px;}
.h31-max{max-height:31px;}
.h32-max{max-height:32px;}
.h33-max{max-height:33px;}
.h34-max{max-height:34px;}
.h35-max{max-height:35px;}
.h36-max{max-height:36px;}
.h37-max{max-height:37px;}
.h38-max{max-height:38px;}
.h39-max{max-height:39px;}
.h40-max{max-height:40px;}
.h50-max{max-height:50px;}
.h60-max{max-height:60px;}
.h70-max{max-height:70px;}
.h80-max{max-height:80px;}
.h90-max{max-height:90px;}
.h100-max{max-height:100px;}
.h150-max{max-height:150px;}
.h200-max{max-height:200px;}
.h250-max{max-height:250px;}
.h300-max{max-height:300px;}
.h350-max{max-height:350px;}
.h400-max{max-height:400px;}
.h450-max{max-height:450px;}
.h500-max{max-height:500px;}
.h550-max{max-height:550px;}
.h600-max{max-height:600px;}
.h650-max{max-height:650px;}
.h700-max{max-height:700px;}
.h750-max{max-height:750px;}
.h800-max{max-height:800px;}
.h850-max{max-height:850px;}
.h900-max{max-height:900px;}
.h950-max{max-height:950px;}
.h1000-max{max-height:1000px;}


.h80vh{
    height:80vh;
}

.h50vh_min{min-height:50vh;}
.h60vh_min{min-height:60vh;}
.h70vh_min{min-height:70vh;}
.h75vh_min{min-height:75vh;}
.h80vh_min{min-height:80vh;}
.h85vh_min{min-height:85vh;}
.h90vh_min{min-height:90vh;}
.h95vh_min{min-height:95vh;}

/* width */

.fw{width:100%;}
.half-w{width:50%;}
.quarter-w{width:25%;}
.upper-quarter-w{width:75%;}
.onethird-w{width:33.33%;}
.wa{width:auto;}
.wn{width:0px;}


.w80_min{min-width: 80px;}
.w100_min{min-width: 100px;}


.wp5p{width:.5%;}
.w5p{width:5%;}
.w10p{width:10%;}
.w11p{width:11%;}
.w12p{width:12%;}
.w12-5p{width:12.5%;}
.w13p{width:13%;}
.w14p{width:14%;}
.w14285p{width:14.285%;} 
.w15p{width:15%;}
.w16p{width:16%;}
.w16-5p{width:16.5%;}
.w17p{width:17%;}
.w17-5p{width:17.5%;}
.w18p{width:18%;}
.w19p{width:19%;}
.w20p{width:20%;}
.w21p{width:21%;}
.w22p{width:22%;}
.w23p{width:23%;}
.w24p{width:24%;}
.w25p{width:25%;}
.w28p{width:28%;}
.w30p{width:30%;}
.w33p{width:33.33%;}
.w35p{width:35%;}
.w40p{width:40%;}
.w41p{width:41%;}
.w42p{width:42%;}
.w43p{width:43%;}
.w44p{width:44%;}
.w45p{width:45%;}
.w46p{width:46%;}
.w47p{width:47%;}
.w48p{width:48%;}
.w49p{width:49%;}
.w50p{width:50%;}
.w51p{width:51%;}
.w52p{width:52%;}
.w53p{width:53%;}
.w54p{width:54%;}
.w55p{width:55%;}
.w60p{width:60%;}
.w65p{width:65%;}
.w66p{width:66%;}
.w67p{width:67%;}
.w68p{width:68%;}
.w69p{width:69%;}
.w70p{width:70%;}
.w75p{width:75%;}
.w78p{width:78%;}
.w80p{width:80%;}
.w81p{width:81%;}
.w82p{width:82%;} 
.w83p{width:83%;}
.w84p{width:84%;}
.w85p{width:85%;}
.w90p{width:90%;}
.w95p{width:95%;}
.w96p{width:96%;}
.w98p{width:98%;}
.w99p{width:99%;}


.w10p-max{max-width:10%;}
.w11p-max{max-width:11%;}
.w12p-max{max-width:12%;}
.w12-5p-max{max-width:12.5%;}
.w13p-max{max-width:13%;}
.w14p-max{max-width:14%;}
.w15p-max{max-width:15%;}
.w16p-max{max-width:16%;}
.w17p-max{max-width:17%;}
.w18p-max{max-width:18%;}
.w19p-max{max-width:19%;}
.w20p-max{max-width:20%;}
.w21p-max{max-width:21%;}
.w22p-max{max-width:22%;}
.w23p-max{max-width:23%;}
.w24p-max{max-width:24%;}
.w25p-max{max-width:25%;}
.w28p-max{max-width:28%;}
.w30p-max{max-width:30%;}
.w40p-max{max-width:40%;}
.w41p-max{max-width:41%;}
.w42p-max{max-width:42%;}
.w43p-max{max-width:43%;}
.w44p-max{max-width:44%;}
.w45p-max{max-width:45%;}
.w50p-max{max-width:50%;}
.w60p-max{max-width:60%;}
.w70p-max{max-width:70%;}
.w75p-max{max-width:75%;}
.w78p-max{max-width:78%;}
.w80p-max{max-width:80%;}
.w90p-max{max-width:90%;}
.w98p-max{max-width:98%;}
.w99p-max{max-width:99%;}
.w100p-max{max-width:100%;}






.fw-im{width:100% !important;}
.half-width-im{width:50% !important;}
.quarter-width-im{width:25% !important;}
.onethird-width-im{width:33.33% !important;}
.auto-width-im{width:auto !important;}
.none-width-im{width:0px !important;}
.no-width{width:0px;}


.w0{width:0px;}
.w1{width:1px;}
.w10{width:10px;}
.w15{width:15px;}
.w20{width:20px;}
.w25{width:25px;}
.w30{width:30px;}
.w35{width:35px;}
.w40{width:40px;}
.w44{width:44px;}
.w45{width:45px;}
.w50{width:50px;}
.w60{width:60px;}
.w65{width:65px;}
.w66{width:66px;}
.w67{width:67px;}
.w68{width:68px;}
.w69{width:69px;}
.w70{width:70px;}
.w80{width:80px;}
.w90{width:90px;}

.w100{width:100px;}
.w110{width:110px;}
.w120{width:120px;}
.w140{width:140px;}
.w145{width:145px;}
.w150{width:150px;}
.w200{width:200px;}
.w210{width:210px;}
.w220{width:220px;}
.w230{width:230px;}
.w240{width:240px;}
.w250{width:250px;}
.w300{width:300px;}
.w310{width:310px;}
.w320{width:320px;}

.w340{width:340px;}

.w350{width:350px;}
.w400{width:400px;}
.w450{width:450px;}
.w500{width:500px;}
.w550{width:550px;}
.w600{width:600px;}
.w650{width:650px;}



.w100-im{width:100px !important;}
.w110-im{width:110px !important;}
.w120-im{width:120px !important;}
.w140-im{width:140px !important;}
.w150-im{width:150px !important;}
.w200-im{width:200px !important;}
.w210-im{width:210px !important;}
.w220-im{width:220px !important;}
.w230-im{width:230px !important;}
.w240-im{width:240px !important;}
.w250-im{width:250px !important;}
.w300-im{width:300px !important;}
.w350-im{width:350px !important;}
.w400-im{width:400px !important;}
.w450-im{width:450px !important;}
.w500-im{width:500px !important;}


.fw-max{max-width:100%;}





.lc{margin:0px auto;}
.lr{margin:0px 0px 0px auto;}
.ll{margin:0px auto 0px 0px;}
 

.overflow-hidden,
.oh{overflow:hidden;}
.tcap{text-transform:capitalize;}
.tlow{text-transform:lowercase;}
.tupp{text-transform:uppercase;}

.tita{font-style: italic;}

.fl{float:left;}
.fr{float:right;}
.fl-im{float:left !important;}
.fr-im{float:right !important;}
.clear{clear:both;}
.cl{clear:left;}
.cr{clear:right;}
.cboth{clear:both;}


.ziin{z-index:99999999999999999999999999 }
.zi4{z-index:4 }
.zi5{z-index:5 }
.zi6{z-index:6 }
.zi7{z-index:7 }
.zi8{z-index:8 }
.zi9{z-index:9 }  
.zi10{z-index:10 }
.zi11{z-index:11 }
.zi12{z-index:12 }
.zi13{z-index:13 }
.zi14{z-index:14 }
.zi15{z-index:15 }


.zi-in{z-index:99999999999999999999999999 !important;}
.zi-4{z-index:4 !important;}
.zi-5{z-index:5 !important;}
.zi-6{z-index:6 !important;}
.zi-7{z-index:7 !important;}
.zi-8{z-index:8 !important;}

.zi-9{z-index:9 !important;}  

.zi-10{z-index:10 !important;}
.zi-11{z-index:11 !important;}
.zi-12{z-index:12 !important;}
.zi-13{z-index:13 !important;}
.zi-14{z-index:14 !important;}
.zi-15{z-index:15 !important;}

.dn{display:none;}
.dn-im{display:none !important;}
.db{display:block;}


.di{display:inline;}
.di-all{display:inline;}
.dib{display:inline-block;}

.dt{display:table;}
.dt-cell{display:table-cell;}

.vh{visibility:hidden;}
.vv{visibility:visible;}
.vv-im{visibility:visible !important;}

.rel{position:relative;}
.abs{position:absolute;}
.fix{position:fixed;}
.sta{position:static;}

 


.underline{text-decoration:underline !important;}
.no-underline{text-decoration:none !important;}
.hover-no-underline:h{text-decoration:none !important;}
.hover-gone-underline{text-decoration:underline !important;}
.hover-gone-underline:h{text-decoration:none !important;}


.tc{text-align:center;}
.tj{text-align:justify;}
.tr{text-align:right;}
.tl{text-align:left;}
.no-text-shadow{text-shadow:none !important;}
.vm{vertical-align: middle;}
.vb{vertical-align: bottom;}

.o0{opacity:0 !important;}
.o1{opacity:.1 !important;}
.o2{opacity:.2 !important;}
.o3{opacity:.3 !important;}
.o4{opacity:.4 !important;}
.o45{opacity:.45 !important;}
.o5{opacity:.5 !important;}
.o55{opacity:.55 !important;}
.o6{opacity:.6 !important;}
.o65{opacity:.65 !important;}
.o7{opacity:.7 !important;}
.o75{opacity:.75 !important;}
.o8{opacity:.8 !important;}
.o85{opacity:.85 !important;}
.o9{opacity:.9 !important;}
.o95{opacity:.95 !important;}
.of{opacity:1 !important;}
 

.b{font-weight:bold !important;}
.b300{font-weight:300 !important;}
.b400{font-weight:400 !important;}
.b500{font-weight:500 !important;}
.b600{font-weight:600 !important;}
.b700{font-weight:700 !important;}
.light{font-weight:100 !important;}

.hover-no-bold:h{
	font-weight:100;
}

.hover-bold-im:h{font-weight:bold !important;}


.cp{cursor:pointer;}

.r1{
    -webkit-border-radius: 1px !important;
    -moz-border-radius: 1px !important;
    border-radius: 1px !important;  
}
.r2{
    -webkit-border-radius: 2px !important;
    -moz-border-radius: 2px !important;
    border-radius: 2px !important;  
}
.r3{
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;  
}

.r4{
-webkit-border-radius: 4px ;
-moz-border-radius: 4px;
border-radius: 4px;
}


.r8{
-webkit-border-radius: 8px ;
-moz-border-radius: 8px;
border-radius: 8px;
}

.r0{
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;    
}



.btn-circle,
.circle-radius,
.r-o{
    -webkit-border-radius: 300px !important;
    -moz-border-radius:300px !important;
    border-radius: 300px !important;   
}
.reset{
    margin:0px !important;
    padding:0px !important;
}
.no-bg{
    background:none;
    background-color:transparent !important;
    background-image:transparent !important;
}
.hover-none:h{
	text-decoration:none !important;
}
.no-bg:h,
.hover-color-none:h{
    background:none;
    background-color:transparent !important;
    border-color:transparent !important;
}
.bg-red{background-color: #ff0054;}
.bg-green{background-color: #64d24c;}
.bg-green-lighter{background-color: #12ff00;}
.bg-blue{background-color: #0B57D0;}


.bg-yellow{background-color: #fcff00;}

.bg-purple{background-color: #b771b0;}
.bg-dark{background-color: #2f353b;}
.bg-black{background-color: #333000;}
.bg-w{background-color: #fff;}


.bg-w-2{background-color: #f4f4f6;}





.bg-grey{background-color: #f9f9f9;}
.bg-t{background: transparent !important;}
.bg-none{background-color: rgba(255,255,255,0.0);}

.bg-white-1{background-color: rgba(255,255,255,.1);}
.bg-white-2{background-color: rgba(255,255,255,.2);}
.bg-white-3{background-color: rgba(255,255,255,.3);}
.bg-white-4{background-color: rgba(255,255,255,.4);}
.bg-white-5{background-color: rgba(255,255,255,.5);}
.bg-white-6{background-color: rgba(255,255,255,.6);}
.bg-white-7{background-color: rgba(255,255,255,.7);}
.bg-white-8{background-color: rgba(255,255,255,.8);}
.bg-white-9{background-color: rgba(255,255,255,.9);}
.bg-black-1{background-color: rgba(0,0,0,.1);}
.bg-black-2{background-color: rgba(0,0,0,.2);}
.bg-black-3{background-color: rgba(0,0,0,.3);}
.bg-black-4{background-color: rgba(0,0,0,.4);}
.bg-black-5{background-color: rgba(0,0,0,.5);}
.bg-black-6{background-color: rgba(0,0,0,.6);}
.bg-black-7{background-color: rgba(0,0,0,.7);}
.bg-black-8{background-color: rgba(0,0,0,.8);}
.bg-black-9{background-color: rgba(0,0,0,.9);}


.bg-h-red:hover{background-color: #ff0054;}
.bg-h-green-lighter:h{background-color: #12ff00;}
.bg-h-blue:hover{background-color: #0B57D0;}
.bg-h-blue2:hover{background-color: #028bc1;}
.bg-h-yellow:hover{background-color: #fcff00;}
.bg-h-grey:hover{background-color: #f9f9f9;}
.bg-h-purple:hover{background-color: #b771b0;}
.bg-h-dark:hover{background-color: #2f353b;}
.bg-h-black:hover{background-color: #333000;}
.bg-h-w:hover{background-color: #fff;}
.bg-h-transparent:hover{background: transparent !important;}
.bg-h-none:hover{background-color: rgba(255,255,255,0.0);}

.bg-active-blue2:focus,
.bg-active-blue2:active
{
	background-color: #028bc1;
}

.bg-dark-alfa-30:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .3);
}
.bg-dark-alfa-50:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .5);
}
.bg-dark-alfa-70:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(17,17,17, .7);
}
.bg-dark-alfa-90:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(17,17,17, .9);
}
.bg-light-alfa:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .97);
}
.bg-light-alfa-30:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .30);
}
.bg-light-alfa-50:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .50);
}
.bg-light-alfa-70:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .7);
}
.bg-light-alfa-90:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .9);
}



.cr{color: #eb5d68 ;}
.cg{color: #5cb85c ;}
.cblu{color: #0B57D0 ;}
.cy{color: #e7711b ;}
.cd{color: #2f353b ;}

.cy2{color: #FF7700 ;}

.cb{color: #333 ;}
.cb_im{color: #333 !important;}




.mc,
.mc:hover{
    color: rgba(117, 117, 117, 1);
}

.mb,
.mb:hover{
    color: rgba(41, 41, 41, 1);
}



.cb_h:hover,
.cb_h:focus,
.cb_h:active
{
    color: #333 !important ;
}



.cgre{color: #ddd ;}
.cw{color: #fff ;}

.won:active,
.won:focus{
	color:#fff !important;
}
  

.crim{color: #eb5d68 !important;}
.cgim{color: #5cb85c !important;}
.cbluim{color: #0B57D0 !important;}
.cyim{color: #e7711b !important;}
.cdim{color: #2f353b !important;}
.cbim{color: #333  !important;}
.cgreim{color: #ddd !important;}
.cwim{color: #fff !important;}



.cblue{color: #0B57D0 !important;}
.crh:h{color: #eb5d68 !important;}
.cgh:h{color: #5cb85c !important;}
.cbluh:h,
.cbluh:focus,
.cbluh:active{color: #0B57D0 !important;}
.cyh:h{color: #e7711b !important;}
.cdh:h{color: #2f353b !important;}
.cbh:h{color: #333000 !important;}
.cgreh:h{color: #ddd !important;}
.cwh:h{color: #fff !important;}

.cwa:active,
.cwa:focus{
	color:#fff !important;
}

.c_ge_2{
    color:#979494
}
.c_ge_3{
    color:#707070
}


.cr_c5{
    color:#c50000;
}


.bg-light-30:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .30);
}
.bg-light-50:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(252,252,252, .50);
}
.bg-dark-10:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .1);
}
.bg-dark-20:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .2);
}
.bg-dark-30:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .3);
}
.bg-dark-40:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .4);
}
.bg-dark-50:before{
	content: " ";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(34,34,34, .5);
}


.bg-dark-opacity-1,.bg-opacity-1{background: rgba(34,34,34, .1);}
.bg-dark-opacity-2,.bg-opacity-2{background: rgba(34,34,34, .2);}
.bg-dark-opacity-3,.bg-opacity-3{background: rgba(34,34,34, .3);}
.bg-dark-opacity-4,.bg-opacity-4{background: rgba(34,34,34, .4);}
.bg-dark-opacity-5,.bg-opacity-5{background: rgba(34,34,34, .5);}
.bg-dark-opacity-6,.bg-opacity-6{background: rgba(34,34,34, .6);}
.bg-dark-opacity-7,.bg-opacity-7{background: rgba(34,34,34, .7);}
.bg-dark-opacity-8,.bg-opacity-8{background: rgba(34,34,34, .8);}
.bg-dark-opacity-9,.bg-opacity-9{background: rgba(34,34,34, .9);}


.bg-dark-opacity-1-hover:h,.bg-opacity-1-hover:h{background: rgba(34,34,34, .1);}
.bg-dark-opacity-2-hover:h,.bg-opacity-2-hover:h{background: rgba(34,34,34, .2);}
.bg-dark-opacity-3-hover:h,.bg-opacity-3-hover:h{background: rgba(34,34,34, .3);}
.bg-dark-opacity-4-hover:h,.bg-opacity-4-hover:h{background: rgba(34,34,34, .4);}
.bg-dark-opacity-5-hover:h,.bg-opacity-5-hover:h{background: rgba(34,34,34, .5);}
.bg-dark-opacity-6-hover:h,.bg-opacity-6-hover:h{background: rgba(34,34,34, .6);}
.bg-dark-opacity-7-hover:h,.bg-opacity-7-hover:h{background: rgba(34,34,34, .7);}
.bg-dark-opacity-8-hover:h,.bg-opacity-8-hover:h{background: rgba(34,34,34, .8);}
.bg-dark-opacity-9-hover:h,.bg-opacity-9-hover:h{background: rgba(34,34,34, .9);}


.bgwo1{background: rgba(255,255,255, .1);}
.bgwo2{background: rgba(255,255,255, .2);}
.bgwo3{background: rgba(255,255,255, .3);}
.bgwo4{background: rgba(255,255,255, .4);}
.bgwo5{background: rgba(255,255,255, .5);}
.bgwo6{background: rgba(255,255,255, .6);}
.bgwo7{background: rgba(255,255,255, .7);}
.bgwo8{background: rgba(255,255,255, .8);}
.bgwo9{background: rgba(255,255,255, .9);}


.br{border:1px solid #eb5d68 !important;}
.bg{border:1px solid #64d24c !important;}
.bblu{border:1px solid #0B57D0 !important;}
.by{border:1px solid #e7711b !important;}
.bp{border:1px solid #b771b0 !important;}

.bd{border:1px solid rgba(0,0,0,.12) !important;}

.bb{border:1px solid #333000 !important;}
.bgre{border:1px solid #e5e5e5 !important;}
.bw{border:1px solid #f1f1f1 !important;}
.bn{border:0px solid #f1f1f1 !important;}


.brh:h{border:1px solid #eb5d68 !important;}
.bgh:h{border:1px solid #64d24c !important;}
.bbluh:h{border:1px solid #0B57D0 !important;}
.byh:h{border:1px solid #e7711b !important;}
.bph:h{border:1px solid #b771b0 !important;}
.bdh:h{border:1px solid #2f353b !important;}
.bbh:h{border:1px solid #333000 !important;}
.bgreh:h{border:1px solid #ddd !important;}
.bwh:h{border:1px solid #f1f1f1 !important;}
.bnh:h{border:0px solid #f1f1f1 !important;}


.btr{border-top:1px solid #eb5d68 !important;}
.bgt{border-top:1px solid #64d24c !important;}
.bblut{border-top:1px solid #0B57D0 !important;}
.byt{border-top:1px solid #e7711b !important;}
.bpt{border-top:1px solid #b771b0 !important;}
.bdt{border-top:1px solid #2f353b !important;}
.bbt{border-top:1px solid #333000 !important;}
.bgret{border-top:1px solid #e5e5e5 !important;}
.bwt{border-top:1px solid #f1f1f1 !important;}
.bnt{border-top:0px solid #f1f1f1 !important;}


.brr{border-right:1px solid #eb5d68 !important;}
.bgr{border-right:1px solid #64d24c !important;}
.bblur{border-right:1px solid #0B57D0 !important;}
.byr{border-right:1px solid #e7711b !important;}
.bpr{border-right:1px solid #b771b0 !important;}
.bdr{border-right:1px solid #c9cbcf !important;}
.bbr{border-right:1px solid #333000 !important;}
.bgrer{border-right:1px solid #ddd !important;}
.bwr{border-right:1px solid #f1f1f1 !important;}
.bnr{border-right:0px solid #f1f1f1 !important;}

.brb{border-bottom:1px solid #eb5d68 !important;}
.bgb{border-bottom:1px solid #64d24c !important;}
.bblub{border-bottom:1px solid #0B57D0 !important;}
.byb{border-bottom:1px solid #e7711b !important;}
.bpb{border-bottom:1px solid #b771b0 !important;}
.bdb{border-bottom:1px solid #2f353b !important;}
.bbb{border-bottom:1px solid #333000 !important;}
.bgreb{border-bottom:1px solid #e5e5e5 !important;}
.bwb{border-bottom:1px solid #f1f1f1 !important;}
.bnb{border-bottom:0px solid #f1f1f1 !important;}


.brl{border-left:1px solid #eb5d68 !important;}
.bgl{border-left:1px solid #64d24c !important;}
.bblul{border-left:1px solid #0B57D0 !important;}
.byl{border-left:1px solid #e7711b !important;}
.bpl{border-left:1px solid #b771b0 !important;}
.bdl{border-left:1px solid #2f353b !important;}
.bbl{border-left:1px solid #333000 !important;}
.bgrel{border-left:1px solid #ddd !important;}
.bwl{border-left:1px solid #f1f1f1 !important;}
.bnl{border-left:0px solid #f1f1f1 !important;}



/* transitions */



/* Buttons */
/* Buttons */
/* Buttons */

.dental-white-btn{
  	color:#fff;
  	border:1px solid #fff;
  	
	padding:10px 35px;
    -webkit-border-radius: 4px ;
    -moz-border-radius: 4px;
    border-radius: 4px;  	

}
.dental-white-btn:h{
  	color:#fff;
  	border:1px solid #0B57D0; 
  	background:#0B57D0;
}

.dental-black-btn{
  	color:#333000;
  	border:1px solid #333000;
  	
	padding:10px 35px;
    -webkit-border-radius: 4px ;
    -moz-border-radius: 4px;
    border-radius: 4px;  	

}
.dental-black-btn:h{
  	color:#fff;
  	border:1px solid #0B57D0; 
  	background:#0B57D0;
}




.dental-blue-btn{
  	color:#fff;
  	border:1px solid #0B57D0;
  	background:#0B57D0;
  	
	padding:10px 35px;
-webkit-border-radius: 4px ;
-moz-border-radius: 4px;
border-radius: 4px;  	

}
.dental-blue-btn:h{
  	color:#333000;
  	border:1px solid #333000; 
  	background:#fff;
}











.btn{
    padding: 15px 20px;   
    
    -webkit-border-radius: 3px;
    -moz-border-radius:3px;
    border-radius: 3px; 
}
/* sizes */
.btn-lg{
    padding: 10px 35px 8px 35px;
    font-size: 18px;
    line-height: 1.3333333;   
}
.btn-sm{
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    line-height: 1.5;  
}
.btn-xs{
    padding: 3px 10px 1px 10px;
    font-size: 12px;
    line-height: 1.5; 
}


/* Button custom padding */
.btn-size-default-1{padding: 8px 30px 6px 30px;font-size:15px;}
.btn-size-lg-1{padding: 12px 45px 10px 45px;font-size:17px;}
.btn-size-sm-1{padding: 6px 17px 4px 17px;font-size:11px;}
.btn-size-xs-1{padding: 4px 13px 2px 13px;font-size:10px;}

.btn-size-default-2{padding: 13px 40px 11px 40px;font-size:16px;}
.btn-size-lg-2{padding: 17px 55px 15px 55px;font-size:18px;}
.btn-size-sm-2{padding: 11px 27px 9px 27px;font-size:12px;}
.btn-size-xs-2{padding: 9px 23px 7px 23px;font-size:11px;}

.btn-size-default-3{padding: 16px 50px 14px 50px;font-size:18px;}
.btn-size-lg-3{padding: 20px 65px 18px 65px;font-size:20px;}
.btn-size-sm-3{padding: 14px 37px 12px 37px;font-size:14px;}
.btn-size-xs-3{padding: 12px 33px 10px 33px;font-size:13px;}


/* dark */
.btn-dark {
    border-color: #2f353b;
    border-width: 1px;
    color: #ffffff;
    background: #2f353b; 
}


.active .btn-dark,
.focus .btn-dark,
.btn-dark:h,
.btn-dark:active,
.btn-dark.active, 
.btn-dark:focus, 
.btn-dark.focus {
    color: #ffffff;
    background: #1d2124;
    border-color: #1d2124; 
}
.open > .dropdown-toggle.btn-dark:h, 
.open > .dropdown-toggle.btn-dark:active, 
.open > .dropdown-toggle.btn-dark.active, 
.open > .dropdown-toggle.btn-dark:focus, 
.open > .dropdown-toggle.btn-dark.focus {
    color: #ffffff;
    background: #1d2124;
    border-color: #1d2124; 
}

.btn-dark-inverse {
    border-color: #2f353b;
    border-width: 1px;
    color: #2f353b;
    background: transparent; 
}

.active .btn-dark-inverse,
.focus .btn-dark-inverse,
.btn-dark-inverse:h,
.btn-dark-inverse:focus,
.btn-dark-inverse.focus,
.btn-dark-inverse:active,
.btn-dark-inverse.active {
    color: #ffffff;
    background: #2f353b;
}

.btn-dark-straight {
    border-color: #2f353b;
    border-width: 1px;
    color: #ffffff;
    background: #2f353b; 
}

.active .btn-dark-straight,
.focus .btn-dark-straight,
.btn-dark-straight:h,
.btn-dark-straight:focus,
.btn-dark-straight.focus,
.btn-dark-straight:active,
.btn-dark-straight.active {
    color: #2f353b;
    background: transparent;
}

.open > .dropdown-toggle.btn-dark-straight:h, 
.open > .dropdown-toggle.btn-dark-straight:active, 
.open > .dropdown-toggle.btn-dark-straight.active, 
.open > .dropdown-toggle.btn-dark-straight:focus, 
.open > .dropdown-toggle.btn-dark-straight.focus {
    color: #2f353b;
    background: transparent;
}
/* blue */


.btn-blue {
    border-color: #0B57D0;
    border-width: 1px;
    color: #ffffff;
    background: #0B57D0;
}

.active .btn-blue,
.focus .btn-blue,
.btn-blue:h,
.btn-blue:focus,
.btn-blue.focus,
.btn-blue:active,
.btn-blue.active {
    color: #ffffff;
    background: #2383c4;
    border-color: #2383c4; 
}
.open > .dropdown-toggle.btn-blue:h, 
.open > .dropdown-toggle.btn-blue:active, 
.open > .dropdown-toggle.btn-blue.active, 
.open > .dropdown-toggle.btn-blue:focus, 
.open > .dropdown-toggle.btn-blue.focus {
    color: #ffffff;
    background: #2383c4;
    border-color: #2383c4; 
}

.btn-blue-inverse {
    border-color: #0B57D0;
    border-width: 1px;
    color: #0B57D0;
    background: transparent;
}

.active .btn-blue-inverse,
.focus .btn-blue-inverse,
.btn-blue-inverse:h,
.btn-blue-inverse:focus,
.btn-blue-inverse.focus,
.btn-blue-inverse:active,
.btn-blue-inverse.active {
    color: #ffffff;
    background: #0B57D0;
}
.btn-blue-straight {
    border-color: #0B57D0;
    border-width: 1px;
    color: #ffffff;
    background: #0B57D0;
}

.active .btn-blue-straight,
.focus .btn-blue-straight,
.btn-blue-straight:h,
.btn-blue-straight:focus,
.btn-blue-straight.focus,
.btn-blue-straight:active,
.btn-blue-straight.active {
    color: #0B57D0;
    background: transparent;
}
.open > .dropdown-toggle.btn-blue-straight:h, 
.open > .dropdown-toggle.btn-blue-straight:active, 
.open > .dropdown-toggle.btn-blue-straight.active, 
.open > .dropdown-toggle.btn-blue-straight:focus, 
.open > .dropdown-toggle.btn-blue-straight.focus {
    color: #0B57D0;
    background: transparent;
}


.btn-black-inverse {
    border-color: #333;
    border-width: 1px;
    color: #333;
    background: transparent; 
}

.active .btn-black-inverse,
.focus .btn-black-inverse,
.btn-black-inverse:h,
.btn-black-inverse:focus,
.btn-black-inverse.focus,
.btn-black-inverse:active,
.btn-black-inverse.active {
    color: #ffffff;
    background: #333000;
}



/* Checkbox */

.checkbox-list {
  margin: 10px 0; 
}
   
.form-horizontal .checkbox-list {
    margin-top: 0px; 
}
  
.checkbox-list .checkbox {
    display: block;
    margin-bottom: 10px; 
}
.c-checkbox {
  position: relative;
}
.c-checkbox input[type=checkbox] {
    visibility: hidden;
    position: absolute; 
}
.c-checkbox label {
    cursor: pointer;
    padding-left: 30px; 
}
.c-checkbox label > span {
    display: block;
    position: absolute;
    left: 0;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s; 
}
.c-checkbox label > span.inc {
    background: #fff;
    left: -10px;
    top: -10px;
    height: 40px;
    width: 40px;
    opacity: 0;
    border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -webkit-border-radius: 50% !important; 
}
.c-checkbox label > span.inc {
    -webkit-animation: growCircle 0.3s ease;
    -moz-animation: growCircle 0.3s ease;
    animation: growCircle 0.3s ease; 
}
.c-checkbox label > .box {
    top: 1px;
    border: 2px solid #d0d7de;
    height: 18px;
    width: 18px;
    z-index: 5;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    transition-delay: 0.2s; 
} 
.c-checkbox label > .check {
    top: -2px;
    left: 6px;
    width: 10px;
    height: 20px;
    border: 2px solid #d0d7de;
    border-top: none;
    border-left: none;
    opacity: 0;
    z-index: 5;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    transition-delay: 0.3s; 
}
.c-checkbox input[type=checkbox]:checked ~ label > .box {
    opacity: 0;
    -webkit-transform: scale(0) rotate(-180deg);
    -moz-transform: scale(0) rotate(-180deg);
    transform: scale(0) rotate(-180deg); 
}
.c-checkbox input[type=checkbox]:checked ~ label > .check {
    opacity: 1;
    -webkit-transform: scale(1) rotate(45deg);
    -moz-transform: scale(1) rotate(45deg);
    transform: scale(1) rotate(45deg); 
}
.c-checkbox input[type=checkbox]:disabled ~ label,
.c-checkbox input[type=checkbox][disabled] ~ label {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; 
}
.c-checkbox input[type=checkbox]:disabled ~ label > .box,
.c-checkbox input[type=checkbox][disabled] ~ label > .box {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; 
}
.c-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
.c-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
    cursor: not-allowed;
    opacity: 0.7;
    filter: alphaopacity=70; 
}
.c-checkbox.has-error label {
    color: #e7505a; 
}
.c-checkbox.has-error label > .box {
    border-color: #e7505a; 
}
.c-checkbox.has-error label > .check {
    border-color: #e7505a; 
}
.c-checkbox.has-info label {
    color: #4eabe6; 
}
.c-checkbox.has-info label > .box {
    border-color: #4eabe6; 
}
.c-checkbox.has-info label > .check {
    border-color: #4eabe6; 
}
.c-checkbox.has-success label {
    color: #5dc09c; 
}
.c-checkbox.has-success label > .box {
    border-color: #5dc09c; 
}
.c-checkbox.has-success label > .check {
    border-color: #5dc09c; 
}
.c-checkbox.has-warning label {
    color: #e7711b; 
}
.c-checkbox.has-warning label > .box {
    border-color: #e7711b; 
}
.c-checkbox.has-warning label > .check {
    border-color: #e7711b; 
}


/* breadcrumb */
.breadcrumb{
    background-color: transparent;
    border-radius: 0px;
    list-style: outside none none;
    margin-bottom: 10px;
    padding: 0px;
    position:relative;
    top:10px;
}

.breadcrumb li{
    display:inline-block;
    line-height:40px;
}

.breadcrumb > li + li::before {
    color: #fff;
    content: "/ ";
    padding: 0 5px;
}
.breadcrumb li{
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: capitalize;
  	letter-spacing:0px;
}
.breadcrumb li a{
    color:#fff;
    font-size: 15px;
    font-weight: 100;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: capitalize;
	letter-spacing:0px;
}

.breadcrumb-black > li + li::before,
.breadcrumb-dark > li + li::before,
.breadcrumb-blue > li + li::before,
.breadcrumb-red > li + li::before,
.breadcrumb-green > li + li::before,
.breadcrumb-purple > li + li::before,
.breadcrumb-yellow > li + li::before,
.breadcrumb-grey > li + li::before{
    color: #fff;
    content: "/ ";
    padding: 0 5px;
}

.breadcrumb-white > li + li::before{
    color: #2f353b;
    content: "/ ";
    padding: 0 5px;
}

.breadcrumb-red li.active,
.breadcrumb-green li.active,
.breadcrumb-blue li.active,
.breadcrumb-purple li.active,
.breadcrumb-yellow li.active,
.breadcrumb-dark li.active,
.breadcrumb-black li.active,
.breadcrumb-grey li.active{
    color:#ddd;
}
.breadcrumb-white li.active{
    color:#333;
}

.breadcrumb-red li a,
.breadcrumb-green li a,
.breadcrumb-blue li a,
.breadcrumb-purple li a,
.breadcrumb-yellow li a,
.breadcrumb-black li a,
.breadcrumb-dark li a,
.breadcrumb-grey li a{
    color:#fff;
}

.breadcrumb-white li a{
    color:#333;
}


/* tabs */
/* inverse-tabs */
.inverse-tabs{
	border-bottom: 1px solid #DCDCDC; 
    float:left;
    width:100%;
    margin:0px;
    padding:0px;  
}
.inverse-tabs > li{
  	float:left;
    margin-right:0px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;  
    border-top:1px solid #DCDCDC;
    border-right:1px solid #DCDCDC;
    border-bottom:0px solid transparent;   
}
.inverse-tabs > li:first-child{border-left:1px solid #DCDCDC;}
.inverse-tabs > li:last-child{margin-right:0px;}
.inverse-tabs > li > a{
    padding: 10px 15px;
    background-color: #f3f3f3; 
    display:block;
    
 	font-size: 16px;
    text-decoration: none;
	font-family: HelveticaNeueW01-Thin, HelveticaNeueW02-Thin, HelveticaNeueW10-35Thin,sans-serif;    
    
    -webkit-transition: back 0s ease-out;
    -moz-transition: all 0s ease-out;
    -o-transition: all 0s ease-out;
    -ms-transition: all 0s ease-out;   
}
.inverse-tabs li.active {
    position:relative;
   	top:1px;
  	z-index: 6;
    background:#fff; 
}




.inverse-tabs li.active a{
	color: #000;
    background:#fff;
  	padding: 10px 15px 10px 15px; 
}



.inverse-tabs.active-bordered-tabs li{border:0px solid transparent;}
.inverse-tabs.active-bordered-tabs li.active{border:1px solid transparent;border-bottom:0px solid transparent;}
.adjusted-tabs.active-bordered-tabs li.active{position:relative;top:1px;}
.adjusted-tabs.active-bordered-tabs li.active a{padding:11px 0px 10px;background:#fff;}

.inverse-tabs.active-red-bordered-tabs li.active{border-color:#eb5d68;}
.inverse-tabs.active-green-bordered-tabs li.active{border-color:#5cb85c;}
.inverse-tabs.active-blue-bordered-tabs li.active{border-color:#3498DB;}
.inverse-tabs.active-yellow-bordered-tabs li.active{border-color:#e7711b;}
.inverse-tabs.active-purple-bordered-tabs li.active{border-color:#b771b0;}
.inverse-tabs.active-dark-bordered-tabs li.active{border-color:#2f353b;}
.inverse-tabs.active-black-bordered-tabs li.active{border-color:#000000;}
.inverse-tabs.active-grey-bordered-tabs li.active{border-color:#ddd;}


.inverse-tabs > li.active.top-red a,
.inverse-tabs > li.active.top-green a,
.inverse-tabs > li.active.top-blue a,
.inverse-tabs > li.active.top-yellow a,
.inverse-tabs > li.active.top-purple a,
.inverse-tabs > li.active.top-dark a,
.inverse-tabs > li.active.top-black a,
.inverse-tabs > li.active.top-grey a{padding:10px 15px 10px 15px;}

.inverse-tabs > li.active.top-red.top-red-1px a,
.inverse-tabs > li.active.top-green.top-green-1px a,
.inverse-tabs > li.active.top-blue.top-blue-1px a,
.inverse-tabs > li.active.top-yellow.top-yellow-1px a,
.inverse-tabs > li.active.top-purple.top-purple-1px a,
.inverse-tabs > li.active.top-dark.top-dark-1px a,
.inverse-tabs > li.active.top-black.top-black-1px a,
.inverse-tabs > li.active.top-grey.top-grey-1px a{padding:11px 15px 10px 15px;}

.inverse-tabs > li.active.top-red.inner-red a{color:#eb5d68;}
.inverse-tabs > li.active.top-green.inner-green a{color:#5cb85c;}
.inverse-tabs > li.active.top-blue.inner-blue a{color:#3498DB;}
.inverse-tabs > li.active.top-yellow.inner-yellow a{color:#e7711b;}
.inverse-tabs > li.active.top-purple.inner-purple a{color:#b771b0;}
.inverse-tabs > li.active.top-dark.inner-dark a{color:#2f353b;}
.inverse-tabs > li.active.top-black.inner-black a{color:#000000;}
.inverse-tabs > li.active.top-grey.inner-grey a{color:#ddd;}


.inverse-tabs li.active.top-red{border-top:1px solid  #eb5d68;}
.inverse-tabs li.active.top-green{border-top:1px solid  #5cb85c;}
.inverse-tabs li.active.top-blue{border-top:1px solid  #3498DB;}
.inverse-tabs li.active.top-yellow{border-top:1px solid  #e7711b;}
.inverse-tabs li.active.top-purple{border-top:1px solid  #b771b0;}
.inverse-tabs li.active.top-dark{border-top:1px solid  #2f353b;}
.inverse-tabs li.active.top-black{border-top:1px solid  #000000;}
.inverse-tabs li.active.top-grey{border-top:1px solid  #ddd;}


.inverse-tabs li.active.top-red.top-red-1px{border-top:1px solid  #eb5d68;}
.inverse-tabs li.active.top-green.top-green-1px{border-top:1px solid  #5cb85c;}
.inverse-tabs li.active.top-blue.top-blue-1px{border-top:1px solid  #3498DB;}
.inverse-tabs li.active.top-yellow.top-yellow-1px{border-top:1px solid  #e7711b;}
.inverse-tabs li.active.top-purple.top-purple-1px{border-top:1px solid  #b771b0;}
.inverse-tabs li.active.top-dark.top-dark-1px{border-top:1px solid  #2f353b;}
.inverse-tabs li.active.top-black.top-black-1px{border-top:1px solid  #000000;}
.inverse-tabs li.active.top-grey.top-grey-1px{border-top:1px solid  #ddd;}

.inverse-tabs-content{
	padding: 20px 0;
	color: #666;
    line-height: 1.8; 
}

/* tabs-normal */
.normal-tabs{
    margin:0px;
    padding:0px;
}
.normal-tabs li{
    display:inline-block;
    margin-right:-4px;
}
.normal-tabs li:last-child{
    margin-right:0px;
}

.normal-tabs li a{
    padding: 10px 25px;
    display:block;

	text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: back 0s ease-out;
    -moz-transition: all 0s ease-out;
    -o-transition: all 0s ease-out;
    -ms-transition: all 0s ease-out;  
}
.normal-tabs li.active{
    position:relative;
    top:1px;
}
.normal-tabs li.active a{
    position:relative;
    top:-1px;
}


.normal-tabs > li.active.inner-red a{color:#eb5d68;}
.normal-tabs > li.active.inner-green a{color:#5cb85c;}
.normal-tabs > li.active.inner-blue a{color:#3498DB;}
.normal-tabs > li.active.inner-yellow a{color:#e7711b;}
.normal-tabs > li.active.inner-purple a{color:#b771b0;}
.normal-tabs > li.active.inner-dark a{color:#2f353b;}
.normal-tabs > li.active.inner-black a{color:#000000;}
.normal-tabs > li.active.inner-grey a{color:#ddd;}



.normal-tabs li.active.bottom-blue a{color:#0B57D0;}
 



.normal-tabs li.active.bottom-red{border-bottom:2px solid  #eb5d68;}
.normal-tabs li.active.bottom-green{border-bottom:2px solid  #5cb85c;}


.normal-tabs li.active.bottom-blue{border-bottom:2px solid  #0B57D0;}
.normal-tabs li.active.bottom-semi-blue{border-bottom:2px solid  #003668;}



.normal-tabs li.active.bottom-yellow{border-bottom:2px solid  #e7711b;}
.normal-tabs li.active.bottom-purple{border-bottom:2px solid  #b771b0;}
.normal-tabs li.active.bottom-dark{border-bottom:2px solid  #2f353b;}
.normal-tabs li.active.bottom-black{border-bottom:2px solid  #000000;}
.normal-tabs li.active.bottom-grey{border-bottom:2px solid  #ddd;}

.normal-tabs li.active.top-red{border-top:2px solid  #eb5d68;}
.normal-tabs li.active.top-green{border-top:2px solid  #5cb85c;}
.normal-tabs li.active.top-blue{border-top:2px solid  #3498DB;}
.normal-tabs li.active.top-yellow{border-top:2px solid  #e7711b;}
.normal-tabs li.active.top-purple{border-top:2px solid  #b771b0;}
.normal-tabs li.active.top-dark{border-top:2px solid  #2f353b;}
.normal-tabs li.active.top-black{border-top:2px solid  #000000;}
.normal-tabs li.active.top-grey{border-top:2px solid  #ddd;}



.normal-tabs li.active.bottom-red-1px{border-bottom:1px solid  #eb5d68;}
.normal-tabs li.active.bottom-green-1px{border-bottom:1px solid  #5cb85c;}
.normal-tabs li.active.bottom-blue-1px{border-bottom:1px solid  #3498DB;}
.normal-tabs li.active.bottom-yellow-1px{border-bottom:1px solid  #e7711b;}
.normal-tabs li.active.bottom-purple-1px{border-bottom:1px solid  #b771b0;}
.normal-tabs li.active.bottom-dark-1px{border-bottom:1px solid  #2f353b;}
.normal-tabs li.active.bottom-black-1px{border-bottom:1px solid  #000000;}
.normal-tabs li.active.bottom-grey-1px{border-bottom:1px solid  #ddd;}

.normal-tabs li.active.top-red-1px{border-top:1px solid  #eb5d68;}
.normal-tabs li.active.top-green-1px{border-top:1px solid  #5cb85c;}
.normal-tabs li.active.top-blue-1px{border-top:1px solid  #3498DB;}
.normal-tabs li.active.top-yellow-1px{border-top:1px solid  #e7711b;}
.normal-tabs li.active.top-purple-1px{border-top:1px solid  #b771b0;}
.normal-tabs li.active.top-dark-1px{border-top:1px solid  #2f353b;}
.normal-tabs li.active.top-black-1px{border-top:1px solid  #000000;}
.normal-tabs li.active.top-grey-1px{border-top:1px solid  #ddd;}

/* common-tabs */


.common-tabs{
    border-bottom:none;
}
.common-tabs li{
    display:inline-block;
}
.common-tabs{
    border-bottom:none;
}
.common-tabs-left,
.common-tabs-right{
    border-bottom:none;
}
.common-tabs-left li,
.common-tabs-right li{
    display:block;
}

.common-tabs-left,
.common-tabs-right{
    border-bottom:none;
}


/* pagination */

.c-content-pagination {
  display: inline-block;
  padding: 0;
  margin: 0; 
}
.c-content-pagination > li {
    list-style: none;
    display: inline-block; 
}
.c-content-pagination > li > span,
.c-content-pagination > li > a {
      width: 40px;
      height: 40px;
      text-align: center;
      display: inline-block;
      font-size: 17px;
      color: #5c6873;
      font-weight: 400;
      padding: 7px 4px 6px 4px;
      border-radius: 40px;
      border: 1px solid #eeeeee; 
}
.c-content-pagination > li.c-space > span {
      border-radius: 0;
      border: 0; 
}
.c-content-pagination.c-square > li > span,
.c-content-pagination.c-square > li > a {
    border-radius: 0; 
}

.c-content-pagination > li.c-active > span, 
.c-content-pagination > li > span:h, 
.c-content-pagination > li.c-active > a, 
.c-content-pagination > li > a:h {
    background: #32c5d2 none repeat scroll 0 0;
    border-color: #32c5d2;
    color: #fff;
}


.pagination-black > li.c-active > span, 
.pagination-black > li > span:h, 
.pagination-black > li.c-active > a, 
.pagination-black > li > a:h {
    background: #333;
    border-color: #ccc;
    color: #fff;
}





/*box shadow*/
.box-shadow-1 {
  box-shadow: 0 0px 1px rgba(0,0,0,0.05), 0 0px 0px rgba(0,0,0,0.05);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.box-shadow-1:h {
  box-shadow: 0 5px 10px rgba(0,0,0,0.10), 0 2px 2px rgba(0,0,0,0.10);
}


.box-shadow-2 {
  box-shadow: 0 5px 10px rgba(0,0,0,0.10), 0 2px 2px rgba(0,0,0,0.10);
}

.box-shadow-3 {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}

.box-shadow-none{
    box-shadow: 0px 0px 0px #fff;
}

/* transitions */
/* bubble animation */
@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25); }
}

@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    -moz-transform: scale(1.25); } 
}

@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 1; }
  70% {
    background: #eee;
    transform: scale(1.25); } 
}














/* Toggles */
/* Toggles */
/* Toggles */
.toggle-wrapper{
	border-left:1px solid #E7E7E7; 
  	padding:30px 0px 30px 1px;
  	
}
.toggle{
    display:block;
    margin: 0 0 0px;
    color:#000;
}
.toggle-header{
    cursor:pointer;
    text-transform:capitalize;
    font-weight:100;
    padding-left:15px;
}


.toggle-grey{
    border-bottom:1px solid #E7E7E7;   
}

.toggle-grey .toggle-header{
  border-bottom:1px solid #E7E7E7;
  position:relative;
  top:1px;
}



.toggle-grey .toggle-header-grey{
  border-bottom:0px solid #E7E7E7; 
  position:relative;
  top:0px;
}


.toggle-header-grey{
    background:#f3f3f3;
    padding:5px 15px;    
}


.toggle-body{
    padding:15px 15px 15px 35px;   
}
.toggle-icon-right .toggle-body{
    padding-left:15px;
}
.toggle-icon-right .toggle-header{
    padding-right:30px;   
}
.toggle-icon-right i{
    float:right;
    margin-top:4px;
    position:relative;
    right:-15px;
}










/* custom Margin */
/* custom Margin */
/* custom Margin */
.no-margin{margin:0px !important;}
.m-auto{margin:auto;}
.m-left-auto{margin-left:auto;}
.m-right-auto{margin-right:auto;}

.m0-im{margin:0px !important;}
.m0-im-all{margin:0px !important;}
.m0{margin:0px;}
.m1{margin:1px;}
.m2{margin:2px;}
.m3{margin:3px;}
.m4{margin:4px;}
.m5{margin:5px;}
.m10{margin:10px;}

.mt10vh{margin-top:10vh;}

.mt-1{margin-top:-1px;}
.mt-2{margin-top:-2px;}
.mt-3{margin-top:-3px;}
.mt-4{margin-top:-4px;}
.mt-5{margin-top:-5px;}
.mt-6{margin-top:-6px;}
.mt-7{margin-top:-7px;}
.mt-8{margin-top:-8px;}
.mt-9{margin-top:-9px;}
.mt-10{margin-top:-10px;}

.mt0{margin-top:0px;}
.mt1{margin-top:1px;}
.mt2{margin-top:2px;}
.mt3{margin-top:3px;}
.mt4{margin-top:4px;}
.mt5{margin-top:5px;}
.mt6{margin-top:6px;}  
.mt7{margin-top:7px;}
.mt8{margin-top:8px;}
.mt9{margin-top:9px;}
.mt10{margin-top:10px;}
.mt11{margin-top:11px;}
.mt12{margin-top:12px;}
.mt13{margin-top:13px;}
.mt14{margin-top:14px;}
.mt15{margin-top:15px;}
.mt16{margin-top:16px;}
.mt17{margin-top:17px;}
.mt20{margin-top:20px;}
.mt25{margin-top:25px;}
.mt30{margin-top:30px;}
.mt35{margin-top:35px;}
.mt40{margin-top:40px;}
.mt45{margin-top:45px;}
.mt50{margin-top:50px;}
.mt55{margin-top:55px;}
.mt60{margin-top:60px;}
.mt65{margin-top:65px;}
.mt70{margin-top:70px;}
.mt75{margin-top:75px;}
.mt80{margin-top:80px;}
.mt85{margin-top:85px;}
.mt90{margin-top:90px;}
.mt95{margin-top:95px;}
.mt100{margin-top:100px;}
.mt110{margin-top:110px;}
.mt120{margin-top:120px;}
.mt130{margin-top:130px;}
.mt140{margin-top:140px;}
.mt150{margin-top:150px;}
.mt200{margin-top:200px;}
.mt300{margin-top:300px;}
.mt400{margin-top:400px;}
.mt500{margin-top:500px;}
.mt600{margin-top:600px;}


.mr0{margin-right:0px;}
.mr1{margin-right:1px;}
.mr2{margin-right:2px;}
.mr3{margin-right:3px;}
.mr4{margin-right:4px;}
.mr5{margin-right:5px;}
.mr6{margin-right:6px;}
.mr10{margin-right:10px;}
.mr15{margin-right:15px;}
.mr20{margin-right:20px;}
.mr21{margin-right:21px;}
.mr25{margin-right:25px;}
.mr30{margin-right:30px;}
.mr35{margin-right:35px;}
.mr40{margin-right:40px;}
.mr45{margin-right:45px;}
.mr50{margin-right:50px;}
.mr55{margin-right:55px;}
.mr60{margin-right:60px;}
.mr65{margin-right:65px;}
.mr70{margin-right:70px;}
.mr75{margin-right:75px;}
.mr80{margin-right:80px;}
.mr85{margin-right:85px;}
.mr90{margin-right:90px;}
.mr95{margin-right:95px;}
.mr100{margin-right:100px;}
.mr200{margin-right:200px;}

.mb0{margin-bottom:0px;}
.mb1{margin-bottom:1px;}
.mb2{margin-bottom:2px;}
.mb3{margin-bottom:3px;}
.mb4{margin-bottom:4px;}
.mb5{margin-bottom:5px;}
.mb6{margin-bottom:6px;}
.mb7{margin-bottom:7px;}
.mb8{margin-bottom:8px;}
.mb9{margin-bottom:9px;}
.mb10{margin-bottom:10px;}
.mb11{margin-bottom:11px;}
.mb12{margin-bottom:12px;}
.mb13{margin-bottom:13px;}
.mb14{margin-bottom:14px;}
.mb15{margin-bottom:15px;}
.mb16{margin-bottom:16px;}
.mb17{margin-bottom:17px;}
.mb18{margin-bottom:18px;}
.mb19{margin-bottom:19px;}
.mb20{margin-bottom:20px;}
.mb25{margin-bottom:25px;}
.mb30{margin-bottom:30px;}
.mb35{margin-bottom:35px;}
.mb40{margin-bottom:40px;}
.mb45{margin-bottom:45px;}
.mb50{margin-bottom:50px;}
.mb55{margin-bottom:55px;}
.mb60{margin-bottom:60px;}
.mb65{margin-bottom:65px;}
.mb70{margin-bottom:70px;}
.mb75{margin-bottom:75px;}
.mb80{margin-bottom:80px;}
.mb85{margin-bottom:85px;}
.mb90{margin-bottom:90px;}
.mb95{margin-bottom:95px;}
.mb100{margin-bottom:100px;}
.mb110{margin-bottom:110px;}
.mb120{margin-bottom:120px;}
.mb130{margin-bottom:130px;}
.mb140{margin-bottom:140px;}
.mb150{margin-bottom:150px;}

.ml0{margin-left:0px;}
.ml1{margin-left:1px;}
.ml2{margin-left:2px;}
.ml3{margin-left:3px;}
.ml4{margin-left:4px;}
.ml5{margin-left:5px;}
.ml6{margin-left:6px;}
.ml7{margin-left:7px;}
.ml8{margin-left:8px;}
.ml9{margin-left:9px;}
.ml10{margin-left:10px;}
.ml15{margin-left:15px;}
.ml20{margin-left:20px;}
.ml21{margin-left:21px;}
.ml22{margin-left:22px;}
.ml23{margin-left:23px;}
.ml24{margin-left:24px;}
.ml25{margin-left:25px;}
.ml26{margin-left:26px;}
.ml27{margin-left:27px;}
.ml28{margin-left:28px;}
.ml30{margin-left:30px;}
.ml31{margin-left:31px;}
.ml32{margin-left:32px;}
.ml33{margin-left:33px;}
.ml35{margin-left:35px;}
.ml40{margin-left:40px;}
.ml41{margin-left:41px;}
.ml42{margin-left:42px;}
.ml43{margin-left:43px;}
.ml45{margin-left:45px;}
.ml46{margin-left:46px;}
.ml50{margin-left:50px;}
.ml51{margin-left:51px;}
.ml52{margin-left:52px;}
.ml55{margin-left:55px;}
.ml60{margin-left:60px;}
.ml65{margin-left:65px;}
.ml70{margin-left:70px;}
.ml75{margin-left:75px;}
.ml80{margin-left:80px;}
.ml85{margin-left:85px;}
.ml90{margin-left:90px;}
.ml95{margin-left:95px;}
.ml100{margin-left:100px;}
.ml200{margin-left:200px;}
.ml300{margin-left:300px;}
.ml400{margin-left:400px;}
.ml500{margin-left:500px;}



.ml0p{margin-left:0%;}
.ml1p{margin-left:1%;}
.ml2p{margin-left:2%;}
.ml3p{margin-left:3%;}
.ml4p{margin-left:4%;}
.ml5p{margin-left:5%;}
.ml6p{margin-left:6%;} 
.ml7p{margin-left:7%;}
.ml8p{margin-left:8%;}
.ml9p{margin-left:9%;}
.ml10p{margin-left:10%;}
.ml11p{margin-left:11%;}
.ml12p{margin-left:12%;}
.ml13p{margin-left:13%;}
.ml14p{margin-left:14%;}
.ml15p{margin-left:15%;}
.ml16p{margin-left:16%;}
.ml17p{margin-left:17%;}
.ml18p{margin-left:18%;}
.ml19p{margin-left:19%;}
.ml20p{margin-left:20%;}
.ml25p{margin-left:25%;}
.ml35p{margin-left:35%;}
.ml40p{margin-left:40%;}
.ml45p{margin-left:45%;}




.mr0p{margin-right:0%;}
.mr1p{margin-right:1%;}
.mr2p{margin-right:2%;}
.mr3p{margin-right:3%;}
.mr4p{margin-right:4%;}
.mr5p{margin-right:5%;}
.mr6p{margin-right:6%;}
.mr7p{margin-right:7%;}
.mr8p{margin-right:8%;}
.mr9p{margin-right:9%;}
.mr10p{margin-right:10%;}


.mt40p{margin-top:40%;}

.m-l5{margin-left:-5px;}
.ml-10{margin-left:-10px;}
.ml-15{margin-left:-15px;}
.ml-20{margin-left:-20px;}
.ml-21{margin-left:-21px;}
.ml-22{margin-left:-22px;}
.ml-23{margin-left:-23px;}
.ml-24{margin-left:-24px;}
.ml-25{margin-left:-25px;}
.ml-30{margin-left:-30px;}
.ml-35{margin-left:-35px;}
.ml-40{margin-left:-40px;}
.ml-45{margin-left:-45px;}
.ml-50{margin-left:-50px;}
.ml-55{margin-left:-55px;}
.ml-60{margin-left:-60px;}
.ml-65{margin-left:-65px;}
.ml-70{margin-left:-70px;}
.ml-75{margin-left:-75px;}
.ml-80{margin-left:-80px;}
.ml-85{margin-left:-85px;}
.ml-90{margin-left:-90px;}
.ml-95{margin-left:-95px;}
.ml-100{margin-left:-100px;}
.ml-200{margin-left:-200px;}

 

.mr-4{margin-right:-4px;}
.mr-5{margin-right:-5px;}
.mr-6{margin-right:-6px;}
.mr-10{margin-right:-10px;}
.mr-15{margin-right:-15px;}
.mr-20{margin-right:-20px;}
.mr-21{margin-right:-21px;}
.mr-25{margin-right:-25px;}
.mr-30{margin-right:-30px;}
.mr-35{margin-right:-35px;}
.mr-40{margin-right:-40px;}
.mr-45{margin-right:-45px;}
.mr-50{margin-right:-50px;}
.mr-55{margin-right:-55px;}
.mr-60{margin-right:-60px;}
.mr-65{margin-right:-65px;}
.mr-70{margin-right:-70px;}
.mr-75{margin-right:-75px;}
.mr-80{margin-right:-80px;}
.mr-85{margin-right:-85px;}
.mr-90{margin-right:-90px;}
.mr-95{margin-right:-95px;}
.mr-100{margin-right:-100px;}
.mr-200{margin-right:-200px;}

.mt-4{margin-top:-4px;}
.mt-5{margin-top:-5px;}
.mt-6{margin-top:-6px;}
.mt-10{margin-top:-10px;}
.mt-15{margin-top:-15px;}
.mt-20{margin-top:-20px;}
.mt-21{margin-top:-21px;}
.mt-25{margin-top:-25px;}
.mt-30{margin-top:-30px;}
.mt-35{margin-top:-35px;}
.mt-40{margin-top:-40px;}
.mt-45{margin-top:-45px;}
.mt-50{margin-top:-50px;}
.mt-55{margin-top:-55px;}
.mt-60{margin-top:-60px;}
.mt-65{margin-top:-65px;}
.mt-70{margin-top:-70px;}
.mt-75{margin-top:-75px;}
.mt-80{margin-top:-80px;}
.mt-85{margin-top:-85px;}
.mt-90{margin-top:-90px;}
.mt-95{margin-top:-95px;}
.mt-100{margin-top:-100px;}
.mt-200{margin-top:-200px;}










/* custom Padding */
/* custom Padding */
/* custom Padding */



.no-padding{padding:0px !important;}
.p0{padding:0px;}
.p1{padding:1px;}
.p2{padding:2px;}
.p3{padding:3px;}
.p4{padding:4px;}
.p5{padding:5px;}
.p6{padding:6px;}
.p7{padding:7px;}
.p8{padding:8px;}
.p9{padding:9px;}
.p10{padding:10px;}
.p15{padding:15px;}
.p20{padding:20px;}
.p25{padding:25px;}
.p26{padding:26px;}
.p27{padding:27px;}
.p28{padding:28px;}
.p29{padding:29px;}
.p30{padding:30px;}
.p35{padding:35px;}
.p40{padding:40px;}
.p45{padding:45px;}
.p48{padding:48px;}
.p50{padding:50px;}
.p60{padding:60px;}
.p70{padding:70px;}
.p80{padding:80px;}
.p90{padding:90px;}
.p100{padding:100px;}




.pt0{padding-top:0px;}
.pt1{padding-top:1px;}
.pt2{padding-top:2px;}
.pt3{padding-top:3px;}
.pt4{padding-top:4px;}
.pt5{padding-top:5px;}
.pt6{padding-top:6px;}
.pt7{padding-top:7px;}
.pt8{padding-top:8px;}
.pt9{padding-top:9px;}
.pt10{padding-top:10px;}
.pt11{padding-top:11px;}
.pt12{padding-top:12px;}
.pt13{padding-top:13px;}
.pt14{padding-top:14px;}
.pt15{padding-top:15px;}
.pt16{padding-top:16px;}
.pt17{padding-top:17px;}
.pt18{padding-top:18px;}
.pt19{padding-top:19px;}
.pt20{padding-top:20px;}
.pt25{padding-top:25px;}
.pt30{padding-top:30px;}
.pt35{padding-top:35px;}
.pt40{padding-top:40px;}
.pt45{padding-top:45px;}
.pt50{padding-top:50px;}
.pt55{padding-top:55px;}
.pt60{padding-top:60px;}
.pt65{padding-top:65px;}
.pt70{padding-top:70px;}
.pt75{padding-top:75px;}
.pt76{padding-top:76px;}
.pt77{padding-top:77px;}
.pt78{padding-top:78px;}
.pt79{padding-top:79px;}
.pt80{padding-top:80px;}
.pt85{padding-top:85px;}
.pt90{padding-top:90px;}
.pt95{padding-top:95px;}
.pt100{padding-top:100px;}
.pt110{padding-top:110px;}
.pt120{padding-top:120px;}
.pt130{padding-top:130px;}
.pt140{padding-top:140px;}
.pt150{padding-top:150px;}
.pt200{padding-top:200px;}














.pr0{padding-right:0px;}
.pr1{padding-right:1px;}
.pr2{padding-right:2px;}
.pr3{padding-right:3px;}
.pr4{padding-right:4px;}
.pr5{padding-right:5px;}
.pr6{padding-right:6px;}
.pr7{padding-right:7px;}
.pr7_5{padding-right:7.5px;}
.pr8{padding-right:8px;}
.pr9{padding-right:9px;}
.pr10{padding-right:10px;}
.pr11{padding-right:11px;}
.pr12{padding-right:12px;}
.pr13{padding-right:13px;}
.pr14{padding-right:14px;}
.pr15{padding-right:15px;}
.pr16{padding-right:16px;}
.pr17{padding-right:17px;}
.pr18{padding-right:18px;}
.pr19{padding-right:19px;}
.pr20{padding-right:20px;}
.pr21{padding-right:21px;}
.pr22{padding-right:22px;}
.pr25{padding-right:25px;}
.pr30{padding-right:30px;}
.pr35{padding-right:35px;}
.pr40{padding-right:40px;}
.pr45{padding-right:45px;}
.pr50{padding-right:50px;}
.pr55{padding-right:55px;}
.pr60{padding-right:60px;}
.pr65{padding-right:65px;}
.pr70{padding-right:70px;}
.pr75{padding-right:75px;}
.pr80{padding-right:80px;}
.pr85{padding-right:85px;}
.pr90{padding-right:90px;}
.pr95{padding-right:95px;}
.pr100{padding-right:100px;}
.pr110{padding-right:110px;}
.pr120{padding-right:120px;}
.pr130{padding-right:130px;}
.pr140{padding-right:140px;}
.pr150{padding-right:150px;}
.pr160{padding-right:160px;}
.pr200{padding-right:200px;}


.pb0{padding-bottom:0px;}
.pb1{padding-bottom:1px;}
.pb2{padding-bottom:2px;}
.pb3{padding-bottom:3px;}
.pb4{padding-bottom:4px;}
.pb5{padding-bottom:5px;}
.pb6{padding-bottom:6px;}
.pb7{padding-bottom:7px;}
.pb8{padding-bottom:8px;}
.pb9{padding-bottom:9px;}
.pb10{padding-bottom:10px;}
.pb11{padding-bottom:11px;}
.pb12{padding-bottom:12px;}
.pb13{padding-bottom:13px;}
.pb14{padding-bottom:14px;}
.pb15{padding-bottom:15px;}
.pb16{padding-bottom:16px;}
.pb17{padding-bottom:17px;}
.pb18{padding-bottom:18px;}
.pb19{padding-bottom:19px;}
.pb20{padding-bottom:20px;}
.pb21{padding-bottom:21px;}
.pb22{padding-bottom:22px;}
.pb23{padding-bottom:23px;}
.pb24{padding-bottom:24px;}
.pb25{padding-bottom:25px;}
.pb30{padding-bottom:30px;}
.pb35{padding-bottom:35px;}
.pb40{padding-bottom:40px;}
.pb45{padding-bottom:45px;}
.pb50{padding-bottom:50px;}
.pb55{padding-bottom:55px;}
.pb60{padding-bottom:60px;}
.pb65{padding-bottom:65px;}
.pb70{padding-bottom:70px;}
.pb75{padding-bottom:75px;}
.pb80{padding-bottom:80px;}
.pb85{padding-bottom:85px;}
.pb90{padding-bottom:90px;}
.pb95{padding-bottom:95px;}
.pb100{padding-bottom:100px;}
.pb110{padding-bottom:110px;}
.pb120{padding-bottom:120px;} 
.pb130{padding-bottom:130px;}
.pb140{padding-bottom:140px;}
.pb150{padding-bottom:150px;}
.pb160{padding-bottom:160px;}
.pb170{padding-bottom:170px;}


.pl0{padding-left:0px;}
.pl1{padding-left:1px;}
.pl2{padding-left:2px;}
.pl3{padding-left:3px;}
.pl4{padding-left:4px;}
.pl5{padding-left:5px;}
.pl6{padding-left:6px;}
.pl7{padding-left:7px;}
.pl7_5{padding-left:7.5px;}
.pl8{padding-left:8px;}
.pl9{padding-left:9px;}


.pl10{padding-left:10px;}
.pl11{padding-left:11px;}
.pl12{padding-left:12px;}
.pl13{padding-left:13px;}
.pl14{padding-left:14px;}
.pl15{padding-left:15px;}
.pl16{padding-left:16px;}
.pl17{padding-left:17px;}
.pl18{padding-left:18px;}
.pl19{padding-left:19px;}
.pl20{padding-left:20px;}
.pl21{padding-left:21px;}
.pl22{padding-left:22px;}
.pl25{padding-left:25px;}
.pl30{padding-left:30px;}
.pl35{padding-left:35px;}
.pl40{padding-left:40px;}
.pl45{padding-left:45px;}
.pl50{padding-left:50px;}
.pl55{padding-left:55px;}
.pl60{padding-left:60px;}
.pl65{padding-left:65px;}
.pl70{padding-left:70px;}
.pl75{padding-left:75px;}
.pl80{padding-left:80px;}
.pl85{padding-left:85px;}
.pl90{padding-left:90px;}
.pl95{padding-left:95px;}
.pl100{padding-left:100px;}
.pl110{padding-left:110px;}
.pl120{padding-left:120px;}
.pl130{padding-left:130px;}
.pl140{padding-left:140px;}
.pl150{padding-left:150px;}
.pl160{padding-left:160px;}



.pr2p{padding-right:2%;}
.pr3p{padding-right:3%;}
.pr4p{padding-right:4%;}
.pr5p{padding-right:5%;}
.pr10p{padding-right:10%;}


.pl5p{padding-left:5%;}
.pl10p{padding-left:10%;}


.p0-im{padding:0px !important;}
.p0-im-all{padding:0px !important;}
.p0-im{padding:0px !important;}
.p1-im{padding:1px !important;}
.p2-im{padding:2px !important;}
.p3-im{padding:3px !important;}
.p4-im{padding:4px !important;}
.p5-im{padding:5px !important;}
.p10-im{padding:10px !important;}
.p15-im{padding:15px !important;}
.p20-im{padding:20px !important;}
.p25-im{padding:25px !important;}
.p30-im{padding:30px !important;}
.p35-im{padding:35px !important;}
.p40-im{padding:40px !important;}
.p45-im{padding:45px !important;}
.p50-im{padding:50px !important;}

.pt0-im{padding-top:0px !important;}
.pt1-im{padding-top:1px !important;}
.pt2-im{padding-top:2px !important;}
.pt3-im{padding-top:3px !important;}
.pt4-im{padding-top:4px !important;}
.pt5-im{padding-top:5px !important;}
.pt10-im{padding-top:10px !important;}
.pt15-im{padding-top:15px !important;}
.pt20-im{padding-top:20px !important;}
.pt25-im{padding-top:25px !important;}
.pt30-im{padding-top:30px !important;}
.pt35-im{padding-top:35px !important;}
.pt40-im{padding-top:40px !important;}
.pt45-im{padding-top:45px !important;}
.pt50-im{padding-top:50px !important;}
.pt55-im{padding-top:55px !important;}
.pt60-im{padding-top:60px !important;}
.pt65-im{padding-top:65px !important;}
.pt70-im{padding-top:70px !important;}
.pt75-im{padding-top:75px !important;}
.pt80-im{padding-top:80px !important;}
.pt85-im{padding-top:85px !important;}
.pt90-im{padding-top:90px !important;}
.pt95-im{padding-top:95px !important;}
.pt100-im{padding-top:100px !important;}

.pr0-im{padding-right:0px !important;}
.pr1-im{padding-right:1px !important;}
.pr2-im{padding-right:2px !important;}
.pr3-im{padding-right:3px !important;}
.pr4-im{padding-right:4px !important;}
.pr5-im{padding-right:5px !important;}
.pr10-im{padding-right:10px !important;}
.pr15-im{padding-right:15px !important;}
.pr20-im{padding-right:20px !important;}
.pr25-im{padding-right:25px !important;}
.pr30-im{padding-right:30px !important;}
.pr35-im{padding-right:35px !important;}
.pr40-im{padding-right:40px !important;}
.pr45-im{padding-right:45px !important;}
.pr50-im{padding-right:50px !important;}
.pr55-im{padding-right:55px !important;}
.pr60-im{padding-right:60px !important;}
.pr65-im{padding-right:65px !important;}
.pr70-im{padding-right:70px !important;}
.pr75-im{padding-right:75px !important;}
.pr80-im{padding-right:80px !important;}
.pr85-im{padding-right:85px !important;}
.pr90-im{padding-right:90px !important;}
.pr95-im{padding-right:95px !important;}
.pr100-im{padding-right:100px !important;}


.pb0-im{padding-bottom:0px !important;}
.pb1-im{padding-bottom:1px !important;}
.pb2-im{padding-bottom:2px !important;}
.pb3-im{padding-bottom:3px !important;}
.pb4-im{padding-bottom:4px !important;}
.pb5-im{padding-bottom:5px !important;}
.pb10-im{padding-bottom:10px !important;}
.pb15-im{padding-bottom:15px !important;}
.pb20-im{padding-bottom:20px !important;}
.pb25-im{padding-bottom:25px !important;}
.pb30-im{padding-bottom:30px !important;}
.pb35-im{padding-bottom:35px !important;}
.pb40-im{padding-bottom:40px !important;}
.pb45-im{padding-bottom:45px !important;}
.pb50-im{padding-bottom:50px !important;}
.pb55-im{padding-bottom:55px !important;}
.pb60-im{padding-bottom:60px !important;}
.pb65-im{padding-bottom:65px !important;}
.pb70-im{padding-bottom:70px !important;}
.pb75-im{padding-bottom:75px !important;}
.pb80-im{padding-bottom:80px !important;}
.pb85-im{padding-bottom:85px !important;}
.pb90-im{padding-bottom:90px !important;}
.pb95-im{padding-bottom:95px !important;}
.pb100-im{padding-bottom:100px !important;}

.pl0-im{padding-left:0px !important;}
.pl1-im{padding-left:1px !important;}
.pl2-im{padding-left:2px !important;}
.pl3-im{padding-left:3px !important;}
.pl4-im{padding-left:4px !important;}
.pl5-im{padding-left:5px !important;}
.pl10-im{padding-left:10px !important;}
.pl15-im{padding-left:15px !important;}
.pl20-im{padding-left:20px !important;}
.pl25-im{padding-left:25px !important;}
.pl30-im{padding-left:30px !important;}
.pl35-im{padding-left:35px !important;}
.pl40-im{padding-left:40px !important;}
.pl45-im{padding-left:45px !important;}
.pl50-im{padding-left:50px !important;}
.pl55-im{padding-left:55px !important;}
.pl60-im{padding-left:60px !important;}
.pl65-im{padding-left:65px !important;}
.pl70-im{padding-left:70px !important;}
.pl75-im{padding-left:75px !important;}
.pl80-im{padding-left:80px !important;}
.pl85-im{padding-left:85px !important;}
.pl90-im{padding-left:90px !important;}
.pl95-im{padding-left:95px !important;}
.pl100-im{padding-left:100px !important;}







.pl0-hover:h{padding-left:0px;}
.pl1-hover:h{padding-left:1px;}
.pl2-hover:h{padding-left:2px;}
.pl3-hover:h{padding-left:3px;}
.pl4-hover:h{padding-left:4px;}
.pl5-hover:h{padding-left:5px;}
.pl6-hover:h{padding-left:6px;}
.pl7-hover:h{padding-left:7px;}
.pl7_5-hover:h{padding-left:7.5px;}
.pl8-hover:h{padding-left:8px;}
.pl9-hover:h{padding-left:9px;}

.pl10-hover:h{padding-left:10px;}
.pl11-hover:h{padding-left:11px;}
.pl12-hover:h{padding-left:12px;}
.pl13-hover:h{padding-left:13px;}
.pl14-hover:h{padding-left:14px;}
.pl15-hover:h{padding-left:15px;}
.pl16-hover:h{padding-left:16px;}
.pl17-hover:h{padding-left:17px;}
.pl18-hover:h{padding-left:18px;}
.pl19-hover:h{padding-left:19px;}
.pl20-hover:h{padding-left:20px;}
.pl25-hover:h{padding-left:25px;}
.pl30-hover:h{padding-left:30px;}
.pl35-hover:h{padding-left:35px;}
.pl40-hover:h{padding-left:40px;}
.pl45-hover:h{padding-left:45px;}
.pl50-hover:h{padding-left:50px;}
.pl55-hover:h{padding-left:55px;}
.pl60-hover:h{padding-left:60px;}
.pl65-hover:h{padding-left:65px;}
.pl70-hover:h{padding-left:70px;}
.pl75-hover:h{padding-left:75px;}
.pl80-hover:h{padding-left:80px;}
.pl85-hover:h{padding-left:85px;}
.pl90-hover:h{padding-left:90px;}
.pl95-hover:h{padding-left:95px;}
.pl100-hover:h{padding-left:100px;}
.pl110-hover:h{padding-left:110px;}
.pl120-hover:h{padding-left:120px;}
.pl130-hover:h{padding-left:130px;}
.pl140-hover:h{padding-left:140px;}
.pl150-hover:h{padding-left:150px;}
.pl160-hover:h{padding-left:160px;}




/* padding percentage*/

.pr1p{padding-right:1%;}
.pl10p{padding-left:10%;}





/* custom line-height */
.lh10{line-height:10px;}
.lh11{line-height:11px;}
.lh12{line-height:12px;}
.lh13{line-height:13px;}
.lh14{line-height:14px;}
.lh15{line-height:15px;}
.lh16{line-height:16px;}
.lh17{line-height:17px;}
.lh18{line-height:18px;}
.lh19{line-height:19px;}
.lh20{line-height:20px;}
.lh21{line-height:21px;}
.lh22{line-height:22px;}
.lh23{line-height:23px;}
.lh24{line-height:24px;}
.lh25{line-height:25px;}
.lh26{line-height:26px;}
.lh27{line-height:27px;}
.lh28{line-height:28px;}
.lh29{line-height:29px;}
.lh30{line-height:30px;}
.lh31{line-height:31px;}
.lh32{line-height:32px;}
.lh33{line-height:33px;}
.lh34{line-height:34px;}
.lh35{line-height:35px;}
.lh36{line-height:36px;}
.lh37{line-height:37px;}
.lh38{line-height:38px;}
.lh39{line-height:39px;}
.lh40{line-height:40px;}
.lh41{line-height:41px;}
.lh42{line-height:42px;}
.lh43{line-height:43px;}
.lh44{line-height:44px;}
.lh45{line-height:45px;}
.lh46{line-height:46px;}
.lh47{line-height:47px;}
.lh48{line-height:48px;}
.lh49{line-height:49px;}
.lh50{line-height:50px;}
.lh51{line-height:51px;}
.lh52{line-height:52px;}
.lh53{line-height:53px;}
.lh54{line-height:54px;}
.lh55{line-height:55px;}
.lh56{line-height:56px;}
.lh57{line-height:57px;}
.lh58{line-height:58px;}
.lh59{line-height:59px;}
.lh60{line-height:60px;}
.lh61{line-height:61px;}
.lh62{line-height:62px;}
.lh63{line-height:63px;}
.lh64{line-height:64px;}
.lh65{line-height:65px;}
.lh66{line-height:66px;}
.lh67{line-height:67px;}
.lh68{line-height:68px;}
.lh69{line-height:69px;}
.lh70{line-height:70px;}
.lh71{line-height:71px;}
.lh72{line-height:72px;}
.lh73{line-height:73px;}
.lh74{line-height:74px;}
.lh75{line-height:75px;}
.lh76{line-height:76px;}
.lh77{line-height:77px;}
.lh78{line-height:78px;}
.lh79{line-height:79px;}
.lh80{line-height:80px;}
.lh81{line-height:81px;}
.lh82{line-height:82px;}
.lh83{line-height:83px;}
.lh84{line-height:84px;}
.lh85{line-height:85px;}
.lh86{line-height:86px;}
.lh87{line-height:87px;}
.lh88{line-height:88px;}
.lh89{line-height:89px;}
.lh90{line-height:90px;}
.lh91{line-height:91px;}
.lh92{line-height:92px;}
.lh93{line-height:93px;}
.lh94{line-height:94px;}
.lh95{line-height:95px;}
.lh96{line-height:96px;}
.lh97{line-height:97px;}
.lh98{line-height:98px;}
.lh99{line-height:99px;} 
.lh100{line-height:100px;}
.lh110{line-height:110px;}
.lh120{line-height:120px;}
.lh130{line-height:130px;}
.lh140{line-height:140px;}
.lh150{line-height:150px;}
.lh160{line-height:160px;}
.lh170{line-height:170px;}
.lh180{line-height:180px;}
.lh190{line-height:190px;}

.lh20-im{line-height:20px !important;}
.lh30-im{line-height:30px !important;}

.lh35-im{line-height:35px !important;}

.lh40-im{line-height:40px !important;}
.lh50-im{line-height:50px !important;}
.lh60-im{line-height:60px !important;}
.lh70-im{line-height:70px !important;}
.lh80-im{line-height:80px !important;}
.lh90-im{line-height:90px !important;}
.lh100-im{line-height:100px !important;}

/* custom font size */
.fs8{font-size:8px;}
.fs9{font-size:9px;}
.fs10{font-size:10px;}
.fs11{font-size:11px;}
.fs12{font-size:12px;}
.fs13{font-size:13px;}
.fs14{font-size:14px;}
.fs15{font-size:15px;}
.fs16{font-size:16px;}
.fs17{font-size:17px;}
.fs18{font-size:18px;}
.fs19{font-size:19px;}
.fs20{font-size:20px;}
.fs21{font-size:21px;}
.fs22{font-size:22px;}
.fs23{font-size:23px;}
.fs24{font-size:24px;}
.fs25{font-size:25px;}
.fs26{font-size:26px;}
.fs27{font-size:27px;}
.fs28{font-size:28px;}
.fs29{font-size:29px;}
.fs30{font-size:30px;}
.fs31{font-size:31px;}
.fs32{font-size:32px;}
.fs33{font-size:33px;}
.fs34{font-size:34px;}
.fs35{font-size:35px;}
.fs36{font-size:36px;}
.fs37{font-size:37px;}
.fs38{font-size:38px;}
.fs39{font-size:39px;}
.fs40{font-size:40px;}
.fs41{font-size:41px;}
.fs42{font-size:42px;}
.fs43{font-size:43px;}
.fs44{font-size:44px;}
.fs45{font-size:45px;}
.fs46{font-size:46px;}
.fs47{font-size:47px;}
.fs48{font-size:48px;}
.fs49{font-size:49px;}
.fs50{font-size:50px;}
.fs51{font-size:51px;}
.fs52{font-size:52px;}
.fs53{font-size:53px;}
.fs54{font-size:54px;}
.fs55{font-size:55px;}
.fs56{font-size:56px;}
.fs57{font-size:57px;}
.fs58{font-size:58px;}
.fs59{font-size:59px;}
.fs60{font-size:60px;}
.fs65{font-size:65px;}
.fs70{font-size:70px;}
.fs75{font-size:75px;}
.fs80{font-size:80px;}
.fs85{font-size:85px;}
.fs90{font-size:90px;}
.fs95{font-size:95px;}
.fs100{font-size:100px;}
.fs110{font-size:110px;}
.fs115{font-size:115px;}
.fs120{font-size:120px;}
.fs130{font-size:130px;}
.fs140{font-size:140px;}
.fs150{font-size:150px;}
.fs160{font-size:160px;}
.fs161{font-size:161px;}
.fs162{font-size:162px;}
.fs163{font-size:163px;}
.fs164{font-size:164px;}
.fs165{font-size:165px;}
.fs166{font-size:166px;}
.fs167{font-size:167px;}
.fs168{font-size:168px;}
.fs169{font-size:169px;}

.fs170{font-size:170px;}
.fs180{font-size:180px;}
.fs190{font-size:190px;}
.fs200{font-size:200px;}



/* custom font size */
.fs8-im{font-size:8px !important;}
.fs9-im{font-size:9px !important;}
.fs10-im{font-size:10px !important;}
.fs11-im{font-size:11px !important;}
.fs12-im{font-size:12px !important;}
.fs13-im{font-size:13px !important;}
.fs14-im{font-size:14px !important;}
.fs15-im{font-size:15px !important;}
.fs16-im{font-size:16px !important;}
.fs17-im{font-size:17px !important;}
.fs18-im{font-size:18px !important;}
.fs19-im{font-size:19px !important;}
.fs20-im{font-size:20px !important;}
.fs21-im{font-size:21px !important;}
.fs22-im{font-size:22px !important;}
.fs23-im{font-size:23px !important;}
.fs24-im{font-size:24px !important;}
.fs25-im{font-size:25px !important;}
.fs26-im{font-size:26px !important;}
.fs27-im{font-size:27px !important;}
.fs28-im{font-size:28px !important;}
.fs29-im{font-size:29px !important;}
.fs30-im{font-size:30px !important;}
.fs31-im{font-size:31px !important;}
.fs32-im{font-size:32px !important;}
.fs33-im{font-size:33px !important;}
.fs34-im{font-size:34px !important;}
.fs35-im{font-size:35px !important;}
.fs36-im{font-size:36px !important;}
.fs37-im{font-size:37px !important;}
.fs38-im{font-size:38px !important;}
.fs39-im{font-size:39px !important;}
.fs40-im{font-size:40px !important;}
.fs41-im{font-size:41px !important;}
.fs42-im{font-size:42px !important;}
.fs43-im{font-size:43px !important;}
.fs44-im{font-size:44px !important;}
.fs45-im{font-size:45px !important;}
.fs46-im{font-size:46px !important;}
.fs47-im{font-size:47px !important;}
.fs48-im{font-size:48px !important;}
.fs49-im{font-size:49px !important;}
.fs50-im{font-size:50px !important;}
.fs51-im{font-size:51px !important;}
.fs52-im{font-size:52px !important;}
.fs53-im{font-size:53px !important;}
.fs54-im{font-size:54px !important;}
.fs55-im{font-size:55px !important;}
.fs56-im{font-size:56px !important;}
.fs57-im{font-size:57px !important;}
.fs58-im{font-size:58px !important;}
.fs59-im{font-size:59px !important;}
.fs60-im{font-size:60px !important;}
.fs65-im{font-size:65px !important;}
.fs70-im{font-size:70px !important;}
.fs75-im{font-size:75px !important;}
.fs80-im{font-size:80px !important;}
.fs85-im{font-size:85px !important;}
.fs90-im{font-size:90px !important;}
.fs95-im{font-size:95px !important;}
.fs100-im{font-size:100px !important;}


/* custom letter spacing */
.ls0{letter-spacing: 0px;}
.ls0-im{letter-spacing: 0px !important;}
.ls1{letter-spacing: 1px;}
.ls2{letter-spacing: 2px;}
.ls3{letter-spacing: 3px;}
.ls4{letter-spacing: 4px;}
.ls5{letter-spacing: 5px;}
.ls6{letter-spacing: 6px;}
.ls7{letter-spacing: 7px;}
.ls8{letter-spacing: 8px;}
.ls9{letter-spacing: 9px;}
.ls10{letter-spacing: 10px;}
.ls11{letter-spacing: 11px;}
.ls12{letter-spacing: 12px;}
.ls13{letter-spacing: 13px;}
.ls14{letter-spacing: 14px;}
.ls15{letter-spacing: 15px;}
.ls16{letter-spacing: 16px;}
.ls18{letter-spacing: 18px;}   
.ls20{letter-spacing: 20px;}   
.ls22{letter-spacing: 22px;}   
.ls24{letter-spacing: 24px;}   
.ls26{letter-spacing: 26px;}  
.ls27{letter-spacing: 27px;}  
.ls28{letter-spacing: 28px;}  
.ls29{letter-spacing: 29px;}  
.ls30{letter-spacing: 30px;}  
.ls35{letter-spacing: 35px;}  
.ls40{letter-spacing: 40px;}  
.ls45{letter-spacing: 45px;}  
.ls50{letter-spacing: 50px;}  
.ls55{letter-spacing: 55px;}  
.ls60{letter-spacing: 60px;}  

/* custom top bottom left right */
.top0{top:0px;}
.top1{top:1px;}
.top2{top:2px;}
.top3{top:3px;}
.top4{top:4px;}
.top5{top:5px;}
.top6{top:6px;}
.top7{top:7px;}
.top8{top:8px;}
.top9{top:9px;}
.top10{top:10px;}
.top11{top:11px;}
.top12{top:12px;}
.top13{top:13px;}
.top14{top:14px;}
.top15{top:15px;}
.top16{top:16px;}
.top17{top:17px;}
.top18{top:18px;}
.top19{top:19px;}
.top20{top:20px;}
.top21{top:21px;}
.top22{top:22px;}
.top23{top:23px;}
.top24{top:24px;}
.top25{top:25px;}
.top26{top:26px;}
.top27{top:27px;}
.top28{top:28px;}
.top29{top:29px;}
.top30{top:30px;}
.top31{top:31px;}
.top32{top:32px;}
.top33{top:33px;}
.top34{top:34px;}
.top35{top:35px;}
.top36{top:36px;}
.top37{top:37px;}
.top38{top:38px;}
.top39{top:39px;}
.top40{top:40px;}
.top41{top:41px;}
.top42{top:42px;}
.top43{top:43px;}
.top44{top:44px;}
.top45{top:45px;}
.top46{top:46px;}
.top47{top:47px;}
.top48{top:48px;}
.top49{top:49px;}
.top50{top:50px;}
.top51{top:51px;}
.top52{top:52px;}
.top53{top:53px;}
.top54{top:54px;}
.top55{top:55px;}
.top56{top:56px;}
.top57{top:57px;}
.top58{top:58px;}
.top59{top:59px;}
.top60{top:60px;}
.top61{top:61px;}
.top62{top:62px;}
.top63{top:63px;}
.top64{top:64px;}
.top65{top:65px;}
.top66{top:66px;}
.top67{top:67px;}
.top68{top:68px;}
.top69{top:69px;}
.top70{top:70px;}
.top80{top:80px;}
.top90{top:90px;}
.top100{top:100px;}
.top110{top:110px;}
.top120{top:120px;}
.top130{top:130px;}
.top140{top:140px;}
.top150{top:150px;}

 
 


.top-1{top:-1px;}
.top-2{top:-2px;}
.top-3{top:-3px;}
.top-4{top:-4px;}
.top-5{top:-5px;}
.top-6{top:-6px;}
.top-7{top:-7px;}
.top-8{top:-8px;}
.top-9{top:-9px;}
.top-10{top:-10px;}
.top-11{top:-11px;}
.top-12{top:-12px;}
.top-13{top:-13px;}
.top-14{top:-14px;}
.top-15{top:-15px;}
.top-20{top:-20px;}
.top-21{top:-21px;}
.top-22{top:-22px;}
.top-23{top:-23px;}
.top-24{top:-24px;}
.top-25{top:-25px;}
.top-26{top:-26px;}
.top-27{top:-27px;}
.top-28{top:-28px;}
.top-29{top:-29px;}
.top-30{top:-30px;}
.top-31{top:-31px;}
.top-32{top:-32px;}
.top-33{top:-33px;}
.top-34{top:-34px;}
.top-35{top:-35px;}
.top-36{top:-36px;}
.top-37{top:-37px;}
.top-38{top:-38px;}
.top-39{top:-35px;}
.top-40{top:-40px;}
.top-41{top:-41px;}
.top-42{top:-42px;}
.top-43{top:-43px;}
.top-44{top:-44px;}
.top-45{top:-45px;}
.top-46{top:-46px;}
.top-47{top:-47px;}
.top-48{top:-48px;}
.top-49{top:-49px;}
.top-50{top:-50px;}
.top-60{top:-60px;}
.top-70{top:-70px;}
.top-80{top:-80px;}
.top-90{top:-90px;}
.top-100{top:-100px;}
.top-110{top:-110px;}
.top-120{top:-120px;}
.top-125{top:-125px;}
.top-130{top:-130px;}

.right0{right:0px;}
.right1{right:1px;}
.right2{right:2px;}
.right3{right:3px;}
.right4{right:4px;}
.right5{right:5px;}
.right6{right:6px;}
.right7{right:7px;}
.right8{right:8px;}
.right9{right:9px;}
.right10{right:10px;}
.right11{right:11px;}
.right12{right:12px;}
.right13{right:13px;}
.right14{right:14px;}
.right15{right:15px;}
.right16{right:16px;}
.right17{right:17px;}
.right18{right:18px;}
.right19{right:19px;}
.right20{right:20px;}
.right21{right:21px;}
.right22{right:22px;}
.right23{right:23px;}
.right24{right:24px;}
.right25{right:25px;}
.right26{right:26px;}
.right27{right:27px;}
.right28{right:28px;}
.right29{right:29px;}
.right30{right:30px;}
.right31{right:31px;}
.right32{right:32px;}
.right33{right:33px;}
.right34{right:34px;}
.right35{right:35px;}
.right36{right:36px;}
.right37{right:37px;}
.right38{right:38px;}
.right39{right:39px;}
.right40{right:40px;}
.right41{right:41px;}
.right42{right:42px;}
.right43{right:43px;}
.right44{right:44px;}
.right45{right:45px;}
.right46{right:46px;}
.right47{right:47px;}
.right48{right:48px;}
.right49{right:49px;}
.right50{right:50px;}
.right60{right:60px;}
.right70{right:70px;}
.right80{right:80px;}
.right90{right:90px;}
.right100{right:100px;}
.right110{right:110px;}
.right120{right:120px;}
.right130{right:130px;}
.right140{right:140px;}
.right150{right:150px;}

.right485{right:485px;}
.right490{right:490px;}
.right495{right:495px;}
.right500{right:500px;}





.right-1{right:-1px;}
.right-2{right:-2px;}
.right-3{right:-3px;}
.right-4{right:-4px;}
.right-5{right:-5px;}
.right-6{right:-6px;}
.right-7{right:-7px;}
.right-8{right:-8px;}
.right-9{right:-9px;}
.right-10{right:-10px;}
.right-11{right:-11px;}
.right-12{right:-12px;}
.right-13{right:-13px;}
.right-14{right:-14px;}
.right-15{right:-15px;}
.right-16{right:-16px;}
.right-17{right:-17px;}
.right-18{right:-18px;}
.right-19{right:-19px;}
.right-20{right:-20px;}
.right-21{right:-21px;}
.right-22{right:-22px;}
.right-23{right:-23px;}
.right-24{right:-24px;}
.right-25{right:-25px;}
.right-26{right:-26px;}
.right-27{right:-27px;}
.right-28{right:-28px;}
.right-29{right:-29px;}
.right-30{right:-30px;}
.right-31{right:-31px;}
.right-32{right:-32px;}
.right-33{right:-33px;}
.right-34{right:-34px;}
.right-35{right:-35px;}
.right-36{right:-6px;}
.right-37{right:-37px;}
.right-38{right:-38px;}
.right-39{right:-39px;}
.right-40{right:-40px;}
.right-41{right:-41px;}
.right-42{right:-42px;}
.right-43{right:-43px;}
.right-44{right:-44px;}
.right-45{right:-45px;}
.right-46{right:-46px;}
.right-47{right:-47px;}
.right-48{right:-48px;}
.right-49{right:-49px;}
.right-50{right:-50px;}
.right-60{right:-60px;}
.right-70{right:-70px;}
.right-80{right:-80px;}
.right-90{right:-90px;}
.right-100{right:-100px;}




.bottom0{bottom:0px;}
.bottom1{bottom:1px;}
.bottom2{bottom:2px;}
.bottom3{bottom:3px;}
.bottom4{bottom:4px;}
.bottom5{bottom:5px;}
.bottom6{bottom:6px;}
.bottom7{bottom:7px;}
.bottom8{bottom:8px;}
.bottom9{bottom:9px;}
.bottom10{bottom:10px;}
.bottom11{bottom:11px;}
.bottom12{bottom:12px;}
.bottom13{bottom:13px;}
.bottom14{bottom:14px;}
.bottom15{bottom:15px;}
.bottom16{bottom:16px;}
.bottom17{bottom:17px;}
.bottom18{bottom:18px;}
.bottom19{bottom:19px;}
.bottom20{bottom:20px;}
.bottom21{bottom:21px;}
.bottom22{bottom:22px;}
.bottom23{bottom:23px;}
.bottom24{bottom:24px;}
.bottom25{bottom:25px;}
.bottom26{bottom:26px;}
.bottom27{bottom:27px;}
.bottom28{bottom:28px;}
.bottom29{bottom:29px;}
.bottom30{bottom:30px;}
.bottom31{bottom:31px;}
.bottom32{bottom:32px;}
.bottom33{bottom:33px;}
.bottom34{bottom:34px;}
.bottom35{bottom:35px;}
.bottom36{bottom:36px;}
.bottom37{bottom:37px;}
.bottom38{bottom:38px;}
.bottom39{bottom:39px;}
.bottom40{bottom:40px;}
.bottom41{bottom:41px;}
.bottom42{bottom:42px;}
.bottom43{bottom:43px;}
.bottom44{bottom:44px;}
.bottom45{bottom:45px;}
.bottom46{bottom:46px;}
.bottom47{bottom:47px;}
.bottom48{bottom:48px;}
.bottom49{bottom:49px;}
.bottom50{bottom:50px;}
.bottom100{bottom:100px;}
.bottom150{bottom:150px;}
.bottom200{bottom:200px;}
.bottom250{bottom:250px;}

.bottom-5{bottom:-5px;}
.bottom-6{bottom:-6px;}
.bottom-7{bottom:-7px;}
.bottom-8{bottom:-8px;}
.bottom-9{bottom:-9px;}
.bottom-10{bottom:-10px;}
.bottom-11{bottom:-11px;}
.bottom-12{bottom:-12px;}
.bottom-13{bottom:-13px;}
.bottom-14{bottom:-14px;}
.bottom-15{bottom:-15px;}
.bottom-16{bottom:-16px;}
.bottom-17{bottom:-17px;}
.bottom-18{bottom:-18px;}
.bottom-19{bottom:-19px;}
.bottom-20{bottom:-20px;}
.bottom-21{bottom:-21px;}
.bottom-22{bottom:-22px;}
.bottom-23{bottom:-23px;}
.bottom-24{bottom:-24px;}
.bottom-25{bottom:-25px;}
.bottom-26{bottom:-26px;}
.bottom-27{bottom:-27px;}
.bottom-28{bottom:-28px;}
.bottom-29{bottom:-29px;}
.bottom-30{bottom:-30px;}
.bottom-31{bottom:-31px;}
.bottom-32{bottom:-32px;}
.bottom-33{bottom:-33px;}
.bottom-34{bottom:-34px;}
.bottom-35{bottom:-35px;}
.bottom-36{bottom:-36px;}
.bottom-37{bottom:-37px;}
.bottom-38{bottom:-38px;}
.bottom-39{bottom:-39px;}
.bottom-40{bottom:-40px;}
.bottom-41{bottom:-41px;}
.bottom-42{bottom:-42px;}
.bottom-43{bottom:-43px;}
.bottom-44{bottom:-44px;}
.bottom-45{bottom:-45px;}
.bottom-46{bottom:-46px;}
.bottom-47{bottom:-47px;}
.bottom-48{bottom:-48px;}
.bottom-49{bottom:-49px;}
.bottom-50{bottom:-50px;}


.left0{left:0px;}
.left1{left:1px;}
.left2{left:2px;}
.left3{left:3px;}
.left4{left:4px;}
.left5{left:5px;}
.left6{left:6px;}
.left7{left:7px;}
.left8{left:8px;}
.left9{left:9px;}
.left10{left:10px;}
.left11{left:11px;}
.left12{left:12px;}
.left13{left:13px;}
.left14{left:14px;}
.left15{left:15px;}
.left16{left:16px;}
.left17{left:17px;}
.left18{left:18px;}
.left19{left:19px;}
.left20{left:20px;}
.left21{left:21px;}
.left22{left:22px;}
.left23{left:23px;}
.left24{left:24px;}
.left25{left:25px;}
.left26{left:26px;}
.left27{left:27px;}
.left28{left:28px;}
.left29{left:29px;}
.left30{left:30px;}
.left31{left:31px;}
.left32{left:32px;}
.left33{left:33px;}
.left34{left:34px;}
.left35{left:35px;}
.left36{left:36px;}
.left37{left:37px;}
.left38{left:38px;}
.left39{left:39px;}
.left40{left:40px;}
.left41{left:41px;}
.left42{left:42px;}
.left43{left:43px;}
.left44{left:44px;}
.left45{left:45px;}
.left46{left:46px;}
.left47{left:47px;}
.left48{left:48px;}
.left49{left:49px;}
.left50{left:50px;}
.left60{left:60px;}
.left70{left:70px;}
.left80{left:80px;}
.left90{left:90px;}
.left100{left:100px;}
.left110{left:110px;}
.left120{left:120px;}
.left125{left:120px;}
.left130{left:130px;}
.left140{left:140px;}
.left150{left:150px;}



.left0p{left:0%;}
.left1p{left:1%;}
.left10p{left:10%;}
.left20p{left:20%;}
.left30p{left:30%;}
.left40p{left:40%;}
.left45p{left:45%;}
.left50p{left:50%;}
.left60p{left:60%;}
.left70p{left:70%;}
.left80p{left:80%;}
.left90p{left:90%;}
.left100p{left:100%;}

 

.left-1{left:-1px;}
.left-2{left:-2px;}
.left-3{left:-3px;}
.left-4{left:-4px;}
.left-5{left:-5px;}
.left-6{left:-6px;}
.left-7{left:-7px;}
.left-8{left:-8px;}
.left-9{left:-9px;}
.left-10{left:-10px;}
.left-11{left:-11px;}
.left-12{left:-12px;}
.left-13{left:-13px;}
.left-14{left:-14px;}
.left-15{left:-15px;}
.left-16{left:-16px;}
.left-17{left:-17px;}
.left-18{left:-18px;}
.left-19{left:-19px;}
.left-20{left:-20px;}
.left-21{left:-21px;}
.left-22{left:-22px;}
.left-23{left:-23px;}
.left-24{left:-24px;}
.left-25{left:-25px;}
.left-26{left:-26px;}
.left-27{left:-27px;}
.left-28{left:-28px;}
.left-29{left:-29px;}
.left-30{left:-30px;}
.left-31{left:-31px;}
.left-32{left:-32px;}
.left-33{left:-33px;}
.left-34{left:-34px;}
.left-35{left:-35px;}
.left-36{left:-36px;}
.left-37{left:-37px;}
.left-38{left:-38px;}
.left-39{left:-39px;}
.left-40{left:-40px;}
.left-41{left:-41px;}
.left-42{left:-42px;}
.left-43{left:-43px;}
.left-44{left:-44px;}
.left-45{left:-45px;}
.left-46{left:-46px;}
.left-47{left:-47px;}
.left-48{left:-48px;}
.left-49{left:-49px;}
.left-50{left:-50px;}
.left-55{left:-55px;}
.left-60{left:-60px;}
.left-65{left:-65px;}
.left-70{left:-70px;}
.left-75{left:-75px;}
.left-80{left:-80px;}
 
.left80p{left:80%;}



.md-show{display:none;}


  

